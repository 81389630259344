@import 'styles/global';
@import 'styles/media';

.comp-modal-container {
  position: fixed !important;
  top: 32px;
  left: 50%;
  display: flex;
  flex-direction: column;
  width: 448px;
  max-height: 80%;
  margin-left: -224px;
  border-radius: 8px;
  background-color: #fff;

  @include media("<tablet") {
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    margin-left: 0;
    border-radius: 0px;
  }

  .comp-modal-header-shadow {
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.08);
  }

  .comp-modal-header {
    position: relative;
    width: 100%;
    min-height: 104px;
    padding: 12px 24px;

    @include media("<tablet") {
      padding: 0px 20px;
      height: 116px;
    }

    .comp-modal-closer {
      position: absolute;
      right: 14px;
      top: 14px;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      background-color: rgba($color: $main-color, $alpha: 0.3);
      text-align: center;
      line-height: 20px;
      font-size: 14px;
      color: #fff;
      cursor: pointer;

      &:hover {
        background-color: rgba($color: $main-color, $alpha: 0.4);
      }

      @include media("<tablet") {
        // display: none;
        background-color: transparent;
        color: $main-color;
        left: 20px;
        top: 22px;
        transform: translateY(-50%);
      }
    }
  }

  .comp-modal-content {
    flex: 1 1 auto;
    overflow-y: auto;
    padding: 12px 24px;
    margin-top: 12px;
  }
}

.comp-modal-pc-animation {
  animation: contact-fade-in ease-in-out 0.3s;
}

.comp-modal-mo-animation {
  position: absolute;
  animation: contact-slid-in ease-in-out 0.3s;
}

@keyframes contact-slid-in {
  0% {
    top: 100%;
  }

  100% {
    top: 0;
  }
}

@keyframes contact-fade-in {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.modal-open {
  overflow: hidden;
}