@import 'styles/global';

.form-creator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 1rem;

  &-label {
    display: block;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $gray-color;
    margin-bottom: 4px;

    &.required {
      &::before {
        content: '*';
        color: #ef4d46;
        margin-right: 3PX;
      }
    }

    .icon {
      margin-left: 4PX;
    }
  }

  section {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    input,
    textarea {
      font-family: inherit;
      font-weight: inherit;
      font-size: 16px;
      color: #7e8697;
      background-color: #F0F1F2;
      padding: 12px;
      border-radius: 8px;
      border: 1px solid transparent;
      vertical-align: middle;
      -webkit-appearance: none;
      transition: all .3s;

      &::placeholder {
        font-size: 16px;
        color: #9196AA;
      }

      &:focus {
        outline: none;
        border: 1px solid $sub-color ;
      }
    }

    input[type=text] {
      height: 48px;
    }

    input[type=checkbox] {
      width: 20px;
      height: 20px;
      padding: .5rem;
      margin-right: .5rem;
      border-radius: 0px;

      &:checked {
        position: relative;
        width: .6rem;
        height: .6rem;
        padding: .5rem;
        border: 1px solid $sub-color;
        background-color: $sub-color;

        &:before {
          content: "✓";
          position: absolute;
          bottom: .03rem;
          right: .1rem;
          color: #fff;

        }
      }
    }

    &.error {

      input,
      textarea {
        border: 1px solid #df3409;
        border-radius: 3px;
        background: rgba(223, 52, 9, .06);

        &:focus {
          outline: none;
          border: 1px solid #df3409;
        }
      }
    }
  }

  &-error {
    font-size: .7rem;
    color: #df3409;
    margin: 0;
    padding: 0;
    line-height: 1.3rem;
    margin-top: .2rem;
  }

  &-textarea {
    resize: none;
  }

  &-checkbox-group {
    margin-top: -.5rem;

    &-row {
      margin-top: .5rem;

      span {
        font-size: .9rem;
        color: #7e8697;
        line-height: 26px;
        margin: 0;
        padding: 0;
        vertical-align: middle;
      }
    }
  }

  &-btn {
    color: #fff;
    width: 5rem;
    height: 2.4rem;
    line-height: 2.5rem;
    text-align: center;
    margin-top: 1rem;
    cursor: pointer;
    border-radius: .6rem;
    box-shadow: #3d9cd4 0 1px 3px;
    background-color: #3d9cd4;
    background-image: linear-gradient(134deg, #ADD7E8 0%, #3d9cd4 100%);

    &.disabled {
      background: #cbcbd9;
      box-shadow: #cbcbd9 0 1px 3px;
      cursor: not-allowed;
    }
  }
}