@import 'styles/global';
@import 'styles/media';

.solution-page-wrapper {

  @include media('<tablet') {
    .slick-slide>div {
      font-size: 0px;
    }
  }

  .carousel {
    position: relative;

    .carousel-wrapper {
      height: 760px;
      position: relative;

      @include media('<tablet') {
        height: 320px;
      }

      .carousel-bg {
        height: 100%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
      }

      .carousel-content {
        position: absolute;
        max-width: 640px;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);

        @include media('<tablet') {
          bottom: 40px;
          padding: 0px 20px;
          top: auto;
          left: auto;
          transform: none;

          .main-sub-title {
            margin-top: 8px;
          }
        }
      }
    }

    .carousel-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0px;
      bottom: 0px;
      backdrop-filter: blur(4px);
      background-color: rgba($color: $main-color, $alpha: 1);
      height: 88px;
      width: 100%;

      @include media('<tablet') {
        position: static;
        overflow: auto;
        justify-content: flex-start;
        height: 72px;
      }

      .circle {
        @include media('<tablet') {
          display: none;
        }

        color: #fff;
        border-color: rgba($color: #fff, $alpha: 0.5);
      }

      &-wrapper {
        margin: 0px 88px;
        display: flex;
        gap: 0px 12px;

        @include media('<tablet') {
          margin: 0px 20px;
        }

        .carousel-footer-item {
          position: relative;
          height: 56px;
          width: 128px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 2px solid transparent;
          border-radius: 8px;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: cover;

          @include media('<tablet') {
            width: 80px;
            height: 40px;
            opacity: 0.5;
          }

          cursor: pointer;

          .title {
            font-weight: 500;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 20px;
            text-align: center;
          }

          .item-mask {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            background-color: rgba($color: #9B9B9B, $alpha: 0.5);

            @include media('<tablet') {
              display: none;
            }
          }
        }

        .carousel-footer-item-active {
          border: 2px solid #FFFFFF;

          @include media('<tablet') {
            width: 80px;
            height: 40px;
            opacity: 1;
          }
        }
      }
    }
  }

  .solutions {
    padding-top: 48px;
    padding-bottom: 72px;
    background-color: #F5F6F7;

    @include media('<tablet') {
      padding: 0px 20px;
      padding-top: 40px;
    }

    background-color: transparent;

    .title {
      margin-bottom: 32px;

      @include media('<tablet') {
        margin-bottom: 16px;
      }
    }

    .grid {
      max-width: 888px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      gap: 24px;

      .solution-item {
        position: relative;
        width: 280px;
        height: 158px;
        color: #fff;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 8px;
        overflow: hidden;

        @include media('<tablet') {
          $imgWidth: calc(100vw - 40px);
          width: $imgWidth;
          height: calc($imgWidth * 0.557);
          gap: 8px
        }

        &>span {
          position: absolute;
          left: 50%;
          top: 50%;
          font-size: 18px;
          font-weight: 400;
          transform: translateX(-50%) translateY(-50%);
          z-index: 1;

          @include media('<tablet') {
            font-weight: 500;
            font-size: 18px;
          }
        }

        &:hover {
          &>.solution-item-img {
            transform: scale(1.2);
          }

        }

        .solution-item-img {
          transition: all .3s;
          border-radius: 8px;
          height: 100%;
        }

      }
    }
  }

  .cases {
    padding: 64px 0px;

    @include media('<tablet') {
      padding: 0px 20px;
      padding-top: 40px;
    }

    .title {
      margin-bottom: 32px;

      @include media('<tablet') {
        margin-bottom: 16px;
      }
    }

    .cases-list {
      max-width: 888px;
      margin: 0 auto;
      $imgWidth: calc(50vw - 30px);
      $imgHeight: calc($imgWidth * 0.557);

      .cases-list-row {
        display: flex;
        gap: 24px;

        .cases-list-item {
          cursor: pointer;
          position: relative;
          height: 320px;

          .item-img-wrapper {
            position: relative;
          }

          .item-img {
            height: 158px;
            width: 280px;
            border-radius: 8px;
            object-fit: cover;

            @include media('<tablet') {
              width: $imgWidth;
              height: $imgHeight;
            }
          }

          .item-play {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
          }

          &:hover>.item-detail {
            opacity: 1;
          }
        }

        .item-logo {
          margin-top: 18px;
          height: 24px;

          @include media('<tablet') {
            margin-top: 16px;
          }
        }

        .item-title {
          margin-top: 8px;
          width: 280px;
          font-weight: 500;
          font-size: 14px;
          color: #01000E;
          line-height: 20px;
          height: 40px;

          @include media('<tablet') {
            width: $imgWidth;
          }

        }

        .item-detail {
          opacity: 0;
          margin-top: 24px;
          color: $sub-color;
          font-weight: 500;
          font-size: 12px;
          color: $sub-color;
          line-height: 16px;
          transition: opacity .3s;

          @include media('<tablet') {
            opacity: 1;
            margin-top: 16px;
          }
        }
      }
    }

    .row-divider {
      margin-top: 32px;
      margin-bottom: 32px;

      @include media('<tablet') {
        margin: 16px 0px;
      }
    }
  }
}