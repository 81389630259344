@import 'styles/global';
@import 'styles/media';

.contact-header {

  .contact-title {
    font-weight: 500;
    font-size: 24px;
    color: $main-color;
    text-align: center;
    line-height: 32px;

    @include media("<tablet") {
      line-height: 20px;
      font-size: 16px;
      color: #01000E;
      margin-left: 20px;
      margin-top: 12px;
    }
  }

  @include media("<tablet") {
    margin-top: 0px;
  }

  .contact-desc {
    margin-top: 4px;
    width: 400px;
    font-weight: 300;
    font-size: 16px;
    color: $gray-color;
    text-align: center;

    @include media("<tablet") {
      margin-top: 16px;
      font-size: 12px;
      line-height: 16px;
      width: auto;
    }
  }
}