@import 'styles/global';
@import 'styles/media';

.solution-detail-wrap {
  .banner-custom-content .production-image {
    margin-bottom: 0;
  }

  .comp-content {
    padding: 0;
  }
}

.solution-detail-now {
  padding-bottom: 72px;

  @include media('<tablet') {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .now-title {
    margin-top: 72px;
    margin-bottom: 64px;

    @include media('<tablet') {
      margin-top: 0px;
      margin-bottom: 16px;
    }
  }

  .industry-group {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 42px;
    max-width: 1242px;
    margin: 0 auto;

    @include media('<tablet') {
      grid-template-columns: repeat(1, 1fr);
      padding: 0px 20px;
      gap: 8px;
    }

    .solution-detail-now-item {
      max-width: 386px;
      display: flex;

      @include media('<tablet') {
        padding: 20px;
        background-color: #FAFAFA;
        border-radius: 8px;

        &>img {
          width: 20px;
          height: 20px;
        }
      }

      .now-detail {
        margin-left: 16px;

        @include media('<tablet') {
          margin-left: 8px;
        }

        &-title {
          font-weight: 600;
          font-size: 18px;
          color: $main-color;
          line-height: 24px;

          @include media('<tablet') {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
          }
        }

        &-desc {
          margin-top: 8px;
          font-weight: 400;
          font-size: 14px;
          color: #9196AA;

          @include media('<tablet') {
            font-weight: 300;
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
  }
}

.solution-detail-cases {
  background-color: #F5F6F7;

  .cases-title {
    padding-top: 64px;
    padding-bottom: 32px;
  }

  .solution-cases {
    padding-bottom: 72px;

    &-content {
      margin: 0 auto;
      margin-top: 32px;
      max-width: 1008px;
      display: grid;
      grid-gap: 24px 24px;
      grid-template-columns: repeat(3, 1fr);

      .card {
        width: 320px;
        background-color: #fff;
        border-radius: 8px;
        transition: opacity .3s;
        opacity: 0.5;

        &:hover {
          opacity: 1;
          box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);

        }

        &-img {
          border-radius: 8px 8px 0px 0px;
        }

        &-content {
          padding: 24px;
          color: $main-color;

          .title {
            min-height: 40px;
            margin-top: 6px;
            font-size: 14px;
            line-height: 20px;
          }

          .time {
            margin-top: 24px;
            color: $gray-color;
            font-weight: 300;
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
  }
}

.solution-detail-scenes {
  .scenes-title {
    margin-top: 64px;
    margin-bottom: 44px;

    @include media('<tablet') {
      margin-top: 40px;
      margin-bottom: 16px;
    }
  }

  .solution-values-wrapper {
    display: flex;
    max-width: 1240px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;

    @include media('<tablet') {
      padding: 0px 20px;
    }

    .values-mask {
      pointer-events: none;
      position: absolute;
      bottom: 0px;
      height: 300px;
      width: 100%;
      background-image: linear-gradient(#fff0, #fff6, #fffa, #ffff);
    }

    .carousel-side {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 48px;

      .side-circles {
        height: 200px;
      }

      .side-buttons {
        display: flex;
        flex-direction: column;
        gap: 32px;
      }
    }

    .carousel-content {
      margin-left: 60px;
      display: inline-block;
      flex: 1;
      max-height: 480px;

      @include media('<tablet') {
        margin-left: 0px;

        .mobile-pagination {
          margin-top: 16px;
          margin-bottom: 40px;
        }
      }

      .main-pic-wrapper {
        .pic-content {
          display: flex !important;
          align-items: center;
          justify-content: center;
          width: 720px !important;
          height: 592px;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }

      .main-content-wrapper {
        width: 440px;

        @include media("<tablet") {
          width: calc(100vw - 40px);
        }

        .main-content {
          border-radius: 8px;
          margin-left: 12px;
          height: 172px;
          cursor: pointer;

          @include media('<tablet') {
            margin-left: 0px;
            height: auto;
          }

          &.main-content-active {
            background-image: linear-gradient(90deg, #F1F7FF 0%, rgba(255, 255, 255, 0.00) 100%);
            box-shadow: -3px 0 0 0 $sub-color;

            @include media('<tablet') {
              background-image: none;
              box-shadow: $sub-color 0 -1px 1px;
              border-bottom: $sub-color solid 2px;

            }
          }

          main {
            padding: 40px 60px;

            @include media('<tablet') {
              padding: 0px;
            }

            .main-content-img-wrapper {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 256px;
            }

            .main-content-img {
              max-width: 100%;
              max-height: 100%;
              // border-radius: 8px 8px 0 0;
            }

            .main-content-title {
              font-weight: 600;
              font-size: 18px;
              color: $main-color;
              line-height: 24px;

              @include media('<tablet') {
                padding: 24px 24px 0px 24px;
              }
            }

            .main-content-desc {
              margin-top: 8px;
              font-weight: 400;
              font-size: 14px;
              color: $gray-color;

              @include media('<tablet') {
                height: 3.5em;
                padding: 0px 24px 0px 24px;
                margin-bottom: 24px;
                -webkit-line-clamp: 3;
              }
            }

            .main-content-detail {
              margin-top: 24px;
              font-weight: 600;
              font-size: 14px;
              color: #01000E;
              line-height: 20px;
            }

            .main-content-detail-item {
              display: flex;
              align-items: center;
              margin-top: 8px;
              font-weight: 400;
              font-size: 14px;
              color: #040411;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}