@import 'styles/global.scss';
@import 'styles/media';

.rich-text-wrapper {
  position: relative;
  margin: 0 auto;
  margin-bottom: 64px;

  @include media("<tablet") {
    padding: 0px 20px;
  }

  .rich-text-nav {
    position: absolute;
    left: 0;
    margin-top: 40px;

    .rich-text-nav-strong {
      color: $main-color;
      font-weight: 500;
    }
  }

  .comp-full-rich-text {
    color: $main-color;
    width: 100%;

    .full-rich-text-content {
      position: relative;

      @include media("<tablet") {
        video {
          width: 100%;
          margin: 0;
        }
      }

      @include media(">=tablet") {
        video {
          width: 70%;
          margin: 0 15%;
        }
      }

      h1,
      h2 {
        margin-top: 40px;
        line-height: 40px;
        font-weight: 500;
      }

      h1 {
        font-size: 22px;
      }

      h2 {
        font-size: 20px;
      }

      li,
      h3 {
        margin: 0;
        padding: 0;
        line-height: 24px;
        font-weight: 600;
        font-size: 18px;
      }

      h3 {
        margin: 24px 0px;
      }

      ul {
        margin-top: 24px;
      }

      video {
        max-width: 100%;
      }

      figure {
        margin: 0;

        >div>div {
          width: 100% !important;
        }
      }

      .slate-img {}

      blockquote {
        position: relative;

        @mixin quote-icon {
          position: absolute;
          left: -40px;
          top: 4px;
          content: '';
          display: inline-block;
          width: 24px;
          height: 18px;
          background-image: url('/static/images/quote.png');
          background-repeat: no-repeat;
          background-size: cover;
        }

        &::before {
          @include quote-icon;
        }

        &::after {
          @include quote-icon;
          left: auto;
          right: -40px;
          transform: rotate(180deg);

          @include media("<tablet") {
            bottom: 4px;
            top: auto;
          }
        }
      }

      p,
      .slate-p {
        margin: 0;
        line-height: 28px;
      }

      .image-wrap {
        text-align: center;
      }

      img {
        max-width: 100%;
        height: auto !important;
      }

      a {
        margin-top: 24px;
        color: $sub-color;
        line-height: 28px;
        font-weight: 400;
        font-size: 16px;
      }

      p>span {
        font-weight: 400;
        color: #676A76;
      }

      hr {
        background-image: linear-gradient(270deg, rgba(231, 233, 240, 0.00) 0%, #E7E9F0 24%, #E7E9F0 51%, #E7E9F0 76%, rgba(231, 233, 240, 0.00) 100%);
        height: 1px;
        border: none;
      }
    }
  }
}