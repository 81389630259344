@import 'styles/media';
@import 'styles/global';

.content-detail-wrapper {

  h3,
  h3 span {
    color: #3c9fdb;
    font-size: 1.1rem !important;

    @include media(">=tablet") {
      font-size: .9rem !important;
    }
  }

  h4,
  h4 span {
    color: $main-color;
    font-weight: 500;
    font-size: 20px;
  }

  p,
  p span {
    color: #676A76;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto !important;
    margin: auto;
  }

  video {
    max-width: 100%;
  }

  span {
    line-height: 1.1rem !important;
  }

  .slate-img {
    figure {
      div {
        width: 100% !important;
      }
    }
  }

  .slate-p {
    min-height: 1rem;
  }
}