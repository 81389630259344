@import 'styles/global';
@import 'styles/media';

.activity-wrapper {
  padding-top: $header-height + 48px;

  @include media('<tablet') {
    padding: 0px 20px;
    padding-top: 16px;
  }

  .activity-title {
    width: 640px;
    margin: 0 auto;
  }

  .activity-carousel {
    position: relative;
    height: 416px;
    max-width: 1044px;
    margin: 0 auto;
    margin-top: 32px;

    @include media('<tablet') {
      width: auto;
      height: auto;
      margin-top: 0px;
      // margin-bottom: 40px;
    }

    .circle-left {
      position: absolute;
      left: 0;
      top: calc(50% - 24px);
    }

    .circle-right {
      position: absolute;
      right: 0;
      top: calc(50% - 24px);
    }

    &-slider {
      margin-left: 72px;

      @include media('<tablet') {
        margin-left: 0px;
      }

      .activity-carousel-content {
        cursor: pointer;
        position: relative;
        width: 900px;
        height: 416px;
        border-radius: 6px;

        @include media('<tablet') {
          $imgWidth: calc(100vw - 40px);
          width: $imgWidth;
          height: calc($imgWidth * 0.557)
        }
      }
    }

    .activity-carousel-desc {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 40px;
      position: absolute;
      bottom: 0;
      left: 72px;
      width: 900px;
      height: 100px;
      backdrop-filter: blur(12px);
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;

      @include media('<tablet') {
        width: calc(100vw - 40px);
        position: static;
        color: $main-color;
        background-color: #FAFAFA;
        box-shadow: 0 2px 0 0 #0366DD;
        border-radius: 0 0 8px 8px;
        padding: 12px 20px;
        margin-bottom: 22px;

        .activity-carousel-pagination {
          position: absolute;
          bottom: -20px;
          left: 50%;
          transform: translateX(-50%);
        }
      }

    }
  }

  .activity-carousel-mb {
    @include media('<tablet') {
      margin-bottom: 40px;
    }
  }

  .activity-filter {
    display: flex;
    justify-content: space-between;
    max-width: 900px;
    margin: 0 auto;
    padding: 32px 0px;
    color: $main-color;

    @include media('<tablet') {
      padding-top: 0px;
      padding-bottom: 0px;
      width: auto;
      flex-direction: column;

      .activity-filter-selector {
        padding: 14px 0px;
        // display: flex;
        // flex-wrap: wrap;
      }
    }

    &>div {
      display: flex;
      gap: 0px 8px;
    }

    .list-type-switch {
      color: $gray-color;
      padding: 2px;
      background-color: #F0F1F2;
      border-radius: 8px;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      .icon-active {
        background-color: #fff;
      }

      span {
        padding: 2px;
        border-radius: 6px;
        cursor: pointer;
      }
    }

    .filter-picker {
      border: none;
      box-shadow: none;
    }

    .filter-select {
      min-width: 120px;
    }

    .filter-input {
      width: 240px;
      border: none;
      box-shadow: none !important;
      background-color: #F0F1F2;

      .ant-input {
        background-color: #F0F1F2;
      }

      @include media('<tablet') {
        width: 100%;
      }
    }
  }

  .activity-list {
    max-width: 900px;
    margin: 0 auto;
    padding-bottom: 64px;

    @include media('<tablet') {
      padding-bottom: 64px;
    }

    .activity-list-row {
      .activity-list-row-item-wrapper {
        display: flex;
        gap: 0px 32px;
        padding: 32px 0px;

        @include media('<tablet') {
          padding: 16px 0px;
        }

        .activity-list-row-item {
          width: 50%;
          position: relative;

          img {
            border-radius: 8px;
            width: 100%;
            height: 230px;
          }

          @include media('<tablet') {
            display: flex;
            width: auto;

            &>div {
              margin-left: 12px;
            }

            img {
              width: 128px;
              height: 72px;
            }
          }

          .item-intro {
            font-weight: 600;
            font-size: 18px;
            color: $main-color;
            line-height: 24px;

            @include media('<tablet') {
              -webkit-line-clamp: 2 !important;
              font-size: 16px;
            }
          }

          .item-time {
            margin-top: 16px;
            margin-bottom: 8px;
            font-weight: 300;
            font-size: 12px;
            color: $gray-color;
            font-size: 16px;
          }

          .item-time-str {
            font-weight: 600;
            font-size: 16px;
            color: $sub-color;
            line-height: 24px;

            @include media('<tablet') {
              font-weight: 400;
              font-size: 12px;
            }
          }
        }

        .activity-list-row-item-small {
          @extend .activity-list-row-item;
          display: flex;

          &>img {
            width: 148px;
            height: 84px;
          }

          &>div {
            margin-left: 12px;

            .item-intro {
              font-size: 14px;
              line-height: 20px;
              -webkit-line-clamp: 2;
            }

            .item-time {
              margin-top: 8px;
            }

            .item-time-str {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .dropdown-class {
    color: $gray-color;
    cursor: pointer;
  }
}