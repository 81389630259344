$select-prefix: 'rc-select';

@mixin search-input-without-border() {
  .#{$select-prefix}-selection-search-input {
    width: 100%;
    padding: 0;
    border: none;
    outline: none;
  }
}

.#{$select-prefix} {
  display: inline-block;
  position: relative;
  font-size: .9rem;
  width: 100%;

  &-disabled {

    &,
    & input {
      cursor: not-allowed;
    }

    .#{$select-prefix}-selector {
      opacity: 0.3;
    }
  }

  &-show-arrow.#{$select-prefix}-disabled-loading {
    .#{$select-prefix}-arrow {
      &-icon::after {
        box-sizing: border-box;
        width: .6rem;
        height: .6rem;
        border-radius: 100%;
        border: 2px solid #cbcbd9;
        border-top-color: transparent;
        border-bottom-color: transparent;
        transform: none;
        margin-top: .2rem;

        animation: rcSelectLoadingIcon 0.5s infinite;
      }
    }
  }

  // ============== Selector ===============
  .#{$select-prefix}-selection-placeholder {
    position: absolute;
    pointer-events: none;
    color: #cbcbd9;
  }

  // ============== Search ===============
  .#{$select-prefix}-selection-search-input {
    appearance: none;

    &::-webkit-search-cancel-button {
      display: none;
      appearance: none;
    }
  }

  // --------------- Single ----------------
  &-single {
    .#{$select-prefix}-selector {
      display: flex;
      align-items: center;
      position: relative;
      padding: .3rem .6rem;
      height: 2.4rem;

      .#{$select-prefix}-selection-search {
        width: 100%;

        &-input {
          width: 100%;
        }
      }

      .#{$select-prefix}-selection-item,
      .#{$select-prefix}-selection-placeholder {
        position: absolute;
        pointer-events: none;
        color: #cbcbd9;
      }

      .#{$select-prefix}-selection-item {
        color: #7e8697;
      }
    }

    // Not customize
    &:not(.#{$select-prefix}-customize-input) {
      .#{$select-prefix}-selector {
        // padding: 1px;
        border: 1px solid #cbcbd9;
        border-radius: 3px;

        @include search-input-without-border;
      }
    }
  }

  // -------------- Multiple ---------------
  &-multiple .#{$select-prefix}-selector {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border: 1px solid #cbcbd9;
    border-radius: 3px;
    padding: .3rem .6rem;
    height: 2.4rem;

    .#{$select-prefix}-selection-item {
      flex: none;
      margin-right: .4rem;
      padding: 0 .4rem;
      color: #7e8697;
      background-color: rgba(203, 203, 217, .20);
      border-radius: 3px;

      &-disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }

      &-remove {
        margin-left: .15rem;

        &:hover {
          color: #3c9fdb;
        }
      }
    }

    .#{$select-prefix}-selection-overflow {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      &-item {
        flex: none;
        max-width: 100%;
      }
    }

    .#{$select-prefix}-selection-search {
      position: relative;
      max-width: 100%;

      &-input,
      &-mirror {
        padding: 1px;
        font-family: system-ui;
      }

      &-mirror {
        position: absolute;
        z-index: 999;
        white-space: nowrap;
        position: none;
        left: 0;
        top: 0;
        visibility: hidden;
      }
    }

    @include search-input-without-border;
  }

  // ================ Icons ================
  &-allow-clear {
    &.#{$select-prefix}-multiple .#{$select-prefix}-selector {
      padding-right: 1rem;
    }

    .#{$select-prefix}-clear {
      position: absolute;
      right: .6rem;
      top: 50%;
      font-size: .8rem;
      width: .8rem;
      height: .8rem;
      color: #cbcbd9;
      text-align: center;
      background-color: #fff;
      height: .8rem;
      line-height: .8rem;
      margin-top: -.4rem;
    }
  }

  &-show-arrow {
    &.#{$select-prefix}-multiple .#{$select-prefix}-selector {
      padding-right: 1rem;
    }

    .#{$select-prefix}-arrow {
      pointer-events: none;
      position: absolute;
      right: .6rem;
      top: 50%;
      font-size: 0;
      margin-top: -.35rem;

      // &-icon::after {
      //   content: '';
      //   border: 6px solid transparent;
      //   width: 0;
      //   height: 0;
      //   display: inline-block;
      //   border-top-color: #cbcbd9;
      //   transform: translateY(5px);
      // }
      &-icon {
        display: block;
        width: .8rem;
        height: .8rem;
        background: url(/static/images/arrow-down.png) no-repeat;
        background-size: contain;
      }
    }
  }

  // =============== Focused ===============
  &-focused {
    .#{$select-prefix}-selector {
      border-color: #3c9fdb !important;
    }
  }

  // ============== Dropdown ===============
  &-dropdown {
    border: 1px solid #cbcbd9;
    border-radius: 3px;
    min-height: 5rem;
    position: absolute;
    background: #fff;
    z-index: 1000;

    &-hidden {
      display: none;
    }
  }

  // =============== Option ================
  &-item {
    font-size: .8rem;
    line-height: 1.5;
    padding: .2rem .8rem;

    // >>> Group
    &-group {
      color: #999;
      font-weight: bold;
      font-size: 80%;
    }

    // >>> Option
    &-option {
      position: relative;
      color: #7e8697;

      &-grouped {
        padding-left: 1.2rem;
      }

      .#{$select-prefix}-item-option-state {
        position: absolute;
        right: 0;
        top: .2rem;
        pointer-events: none;
        display: none;
      }

      // ------- Active -------
      &-active {
        color: #fff;
        background: #3c9fdb;
      }

      // ------ Disabled ------
      &-disabled {
        color: #999;
      }
    }

    // >>> Empty
    &-empty {
      text-align: center;
      color: #999;
    }
  }
}

.#{$select-prefix}-selection__choice-zoom {
  transition: all 0.3s;
}

.#{$select-prefix}-selection__choice-zoom-appear {
  opacity: 0;
  transform: scale(0.5);

  &-active {
    opacity: 1;
    transform: scale(1);
  }
}

.#{$select-prefix}-selection__choice-zoom-leave {
  opacity: 1;
  transform: scale(1);

  &-active {
    opacity: 0;
    transform: scale(0.5);
  }
}

@mixin effect() {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
}

.#{$select-prefix}-dropdown {

  &-slide-up-enter,
  &-slide-up-appear {
    @include effect;
    opacity: 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-play-state: paused;
  }

  &-slide-up-leave {
    @include effect;
    opacity: 1;
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    animation-play-state: paused;
  }

  &-slide-up-enter#{$select-prefix}-slide-up-enter-active#{$select-prefix}-placement-bottomLeft,
  &-slide-up-appear#{$select-prefix}-slide-up-appear-active#{$select-prefix}-placement-bottomLeft {
    animation-name: rcSelectDropdownSlideUpIn;
    animation-play-state: running;
  }

  &-slide-up-leave#{$select-prefix}-slide-up-leave-active#{$select-prefix}-placement-bottomLeft {
    animation-name: rcSelectDropdownSlideUpOut;
    animation-play-state: running;
  }

  &-slide-up-enter#{$select-prefix}-slide-up-enter-active#{$select-prefix}-placement-topLeft,
  &-slide-up-appear#{$select-prefix}-slide-up-appear-active#{$select-prefix}-placement-topLeft {
    animation-name: rcSelectDropdownSlideDownIn;
    animation-play-state: running;
  }

  &-slide-up-leave#{$select-prefix}-slide-up-leave-active#{$select-prefix}-placement-topLeft {
    animation-name: rcSelectDropdownSlideDownOut;
    animation-play-state: running;
  }
}

@keyframes rcSelectDropdownSlideUpIn {
  0% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }

  100% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
}

@keyframes rcSelectDropdownSlideUpOut {
  0% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }

  100% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }
}

@keyframes rcSelectLoadingIcon {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}