.mobile-tabs-title {
  margin-top: 20px;
  margin-bottom: 16px;

}

.mobile-solution-wrapper {
  margin: 0 auto;
  padding: 0px 20px;

  img {
    width: 100%;
    border-radius: 8px;
    min-height: 60px;
  }
  .mobile-solution-item {
    position: relative;
    margin-bottom: 8px;
    font-size: 0px;
    &-footer {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 40px;
      width: 100%;
      background-color: rgba($color: #000000, $alpha: 0.4);
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      backdrop-filter: blur(12px);
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }
}
