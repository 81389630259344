@import 'styles/global';
@import 'styles/media';

.periodical-detail-wrapper {
  max-width: 900px;
  margin: 0 auto;
  padding-top: $header-height;

  @include media('<tablet') {
    width: auto;
    padding-top: 0px;
  }

  .detail-item {
    padding-top: 48px;
    display: flex;

    &>img {
      height: 450px;
      width: 370px;
    }

    @include media('<tablet') {
      padding-top: 16px;
      flex-direction: column;

      &>img {
        margin: 0px 20px;
        height: auto;
        width: auto;
      }
    }

    &-content {
      margin-left: 64px;
      padding-top: 32px;

      @include media('<tablet') {
        padding: 0px 20px;
        margin-left: 0px;
        margin-top: 24px;
        margin-bottom: 60px;
      }

      &>span {
        line-height: 16px;
        font-weight: 600;
        font-size: 12px;
        color: $sub-color;
        padding: 4px 12px;
        background-color: #EDF5FF;
        border-radius: 4px;
      }

      .title {
        margin-top: 12px;
        font-weight: 500;
        font-size: 32px;
        color: $main-color;
        line-height: 40px;

        @include media('<tablet') {
          margin-top: 4px;
          font-size: 24px;
          line-height: 32px;
        }
      }

      .intro {
        margin-top: 32px;
        font-weight: 300;
        font-size: 16px;
        color: #9196AA;
        line-height: 24px;

        @include media('<tablet') {
          margin-top: 16px;
          font-size: 14px;
        }
      }

      .detail-btn {
        margin-top: 32px;
      }
    }
  }

  .divider {
    margin: 64px 0px;
  }
}

.cases-periodical {
  @include media('<tablet') {
    .card {
      overflow: initial !important;
    }
  }

  @include media('>=tablet') {
    .card {
      overflow: hidden;
    }
  }

  .card {
    position: relative;

    &:hover {
      .card-content {
        display: flex;
      }

      .card-mask {
        background-color: #000;
        opacity: 0.4;
      }

      .card-mask-footer {
        display: none;
      }
    }
  }

  .card-mask {
    background-color: transparent;
    z-index: 2;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
  }

  .card-mask-footer {
    color: #fff;
    backdrop-filter: blur(12px);
    height: 80px;
    width: 100%;
    font-size: 20px;
    line-height: 20px;
    padding: 30px 20px;
    z-index: 3;
    position: absolute;
    bottom: 0;
  }

  .card-content {
    display: none;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    flex-direction: column;
    align-items: center;

    .group {
      color: #fff;
      font-weight: 400;
      font-size: 20px;
      text-align: left;
      margin-bottom: 20px;
      width: 100%;
    }

    .title {
      color: #fff;
      font-weight: 300;
      flex: 1;
      font-size: 16px;
      line-height: 24px;
    }

    .name {
      color: #fff;
      font-weight: 400;
      font-size: 20px;
      margin-bottom: 20px;
      text-align: center;
    }

    .card-logo {
      height: 40px;
      width: 60%;
    }

    .buttonDefault {
      color: #fff !important;
      border-color: #fff !important;
      margin-top: 44px;
      margin-bottom: 12px;
    }
  }

  .card-img {
    height: 520px;
    border-radius: 8px;
  }
}

.periodical-footer {
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;


  @include media('<tablet') {
    height: 300px;
    margin-bottom: 50px;

    a {
      margin-top: 320px;
    }
  }

  @include media('>=tablet') {
    height: 400px;

    a {
      margin-top: 330px;
    }
  }
}