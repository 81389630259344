@import 'styles/global';
@import 'styles/media';

.main-title-wrapper {
  color: $main-color;
  .main-sub-title {
    margin-top: 8px;
    // height: 40px;
    font-weight: 400;
    font-size: 14px;
    color: $gray-color;
    line-height: 20px;
    @include media("<tablet") {
      font-size: 12px;
      line-height: 18px;
      margin-top: 2px;
    }
  }
  .main-title {
    line-height: 40px;
    font-weight: 500;
    font-size: 28px;
    @include media("<tablet") {
      font-size: 20px;
      line-height: 28px;
    }
    &-xl {
      @extend .main-title;
      line-height: 42px;
      font-size: 36px;
      @include media("<tablet") {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
      }
    }
    &-lg {
      @extend .main-title;
      line-height: 28px;
      font-size: 20px;
      font-weight: 600;
      @include media("<tablet") {
        font-weight: 500
      }
    }
    &-md {
      @extend .main-title;
      line-height: 24px;
      font-size: 18px;
      font-weight: 600;
    }
    &-sm {
      @extend .main-title;
      line-height: 20px;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.left {
  text-align: left;
}
.right {
  text-align: right;
}
.center {
  text-align: center;
}
.dark {
  color: #fff !important;
}
