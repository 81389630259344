@import 'styles/global';

.mobile-date-picker {
  padding-right: 20px;
  position: relative;
  margin: auto;
  width: 120px;
  &-content {
    font-size: 14px;
    line-height: 24px;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 8px;
    color: rgba(0, 0, 0, 0.25);
  }
  &-content-active {
    color: $sub-color;
  }
  &-closer {
    position: absolute;
    right: 0px;
    top: 4px;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    background-color: rgba($color: $main-color, $alpha: 0.3);
    text-align: center;
    line-height: 16px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    &:hover{
      background-color: rgba($color: $main-color, $alpha: 0.4);
    }
  }
  &-down {
    position: absolute;
    right: 0px;
    top: 4px;
    width: 16px;
    height: 16px;
    color: rgba(0, 0, 0, 0.25);
  }
}
