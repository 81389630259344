@media screen and (max-width: 768px) {
  .category-wrap {
    .category-footer {
      padding: 30px;
    }
  }

  .category-footer {
    .item {
      .title {
        >img {
          width: 20px;
        }

        >span {
          font-size: 14px;
        }
      }

      .content {
        font-size: 12px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .category-wrap {
    .comp-banner .banner-content>div {
      width: 60%;
    }
  }

  .category-footer {
    .item {
      .title {
        >img {
          width: 30px;
        }

        >span {
          font-size: 20px;
        }
      }

      .content {
        font-size: 14px;
      }
    }
  }
}

.category-wrap {
  .comp-banner .banner-sub-title {
    font-weight: inherit;
  }
}

.category-footer {
  display: flex;
  flex-direction: row;
  background-color: #f6f7f8;
  padding: 30px 100px;
  justify-self: flex-start;
  overflow: auto;

  .item {
    flex: 1;
    padding-right: 20px;
    min-width: 200px;

    .title {

      >img,
      >span {
        vertical-align: middle;
      }

      >span {
        font-weight: 500;
        margin-left: 8px;
      }
    }

    .content {
      color: #9498a9;
      height: 60px;
      margin: 12px 0;
      line-height: 20px;
      overflow: hidden;
      word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
}