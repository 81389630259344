@import 'styles/global';
@import 'styles/media';

.periodical-wrapper {
  padding-top: $header-height + 48px;
  width: 1000px;
  margin: 0 auto;

  @include media('<tablet') {
    width: auto;
    padding: 0px 20px;
    padding-bottom: 60px;
  }

  .periodical-title {
    width: 640px;
    margin: 0 auto;
  }

  .periodical-list {
    margin-top: 48px;

    @include media('<tablet') {
      margin-top: 0px;

      &>div {
        margin-top: 38px;
        display: flex;
        flex-direction: column;
        row-gap: 32px;
      }
    }

    .periodical-list-row {
      display: flex;
      justify-content: space-between;
      gap: 0px 60px;

      @include media('<tablet') {
        flex-direction: column;
        gap: 38px 0px;
      }

      &-item {
        width: 50%;
        display: flex;
        cursor: pointer;

        img {
          width: 160px;
          height: 192px;
        }

        @include media('<tablet') {
          width: auto;

          &>img {
            width: 106px;
            height: 128px;
          }
        }

        .content {
          margin-left: 20px;

          @include media('<tablet') {
            margin-left: 12px;
          }

          .title {
            margin-top: 32px;
            margin-bottom: 20px;
            font-weight: 600;
            font-size: 18px;
            color: $main-color;
            line-height: 28px;

            @include media('<tablet') {
              font-size: 16px;
              line-height: 24px;
              margin-bottom: 12px;
              margin-top: 16px;
            }
          }

          .intro {
            font-weight: 300;
            font-size: 16px;
            color: #9196AA;
            line-height: 20px;

            @include media('<tablet') {
              font-size: 14px;
            }
          }
        }
      }
    }

    .divider {
      margin: 64px 0px;
    }
  }
}