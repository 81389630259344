@import 'styles/media';
@import 'styles/global';

.content-detail-header {
  color: $main-color;
  .content-line {
    margin-top: 24px;
  }
  h2 {
    margin-block: 0;
    font-weight: 500;
    font-size: 32px;
    color: $main-color;
    line-height: 40px;
    margin-top: 48px;
    margin-bottom: 8px;
    @include media("<tablet") {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }

  .content-detail-other {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    line-height: 1.8rem;
  }

  .content-detail-tags {
    span {
      margin-right: .5rem;
      white-space: nowrap;

      svg {
        font-size: .9rem;
        margin-right: .15rem;
      }
    }
  }
  .content-detail-time {
    font-weight: 300;
    font-size: 16px;
    color: $gray-color;
    line-height: 28px;
  }

  .content-detail-source {
    margin-top: .6rem;

    a {
      color: #3C9FDB;
      margin-right: .6rem;
    }
  }
  .content-detail-desc {
    margin-top: 8px;
    font-weight: 600;
    font-size: 16px;
    color: $main-color;
    line-height: 28px;
  }
}
