@import 'styles/media';
@import 'styles/global';

$nav-height: $header-height;

.comp-ceiling {
  display: flex;
  position: absolute;
  width: 100%;
  height: $nav-height;
  // box-shadow: 0 2px 11px 0 rgba(39,40,43,0.08), inset 0 -1px 4px 0 rgba(255,255,255,0.40);
  transition: top .2s, background-color .5s;
  z-index: 99;

  &.light {
    background-color: #fff;
  }

  .popover:hover {
    li {
      .link {
        color: #0366DD !important
      }

      &::after {
        margin-left: 0;
        width: 100%;
      }
    }
  }

  &.has-background {
    background-color: rgba(255, 255, 255, .8);
    box-shadow: rgba(0, 0, 0, .05) 0 1px 5px;

    &.dark {
      background-color: rgba(0, 0, 0, .3);
    }
  }

  .logo-search {
    position: absolute;
    left: -1000px;
  }

  .logo-light {
    display: block;
  }

  .logo-dark {
    display: none;
  }

  &.dark {
    .logo-light {
      display: none;
    }

    .logo-dark {
      display: block;
    }
  }

  @include media(">=tablet") {
    &.has-background {
      position: fixed;
      top: -3rem;

      &.show {
        top: 0;
      }
    }

    &:hover,
    &.dark:hover {
      background-color: #fff;

      .logo-light {
        display: block;
      }

      .logo-dark {
        display: none;
      }

      .ceiling-menu li .link {
        color: $main-color;
      }

      .lang {
        .comp-dropdown-list {
          color: $main-color;
        }
      }
    }

    &.dark {
      .ceiling-menu li .link {
        color: #fff;
      }

      .lang {
        .comp-dropdown-list {
          color: #fff;
        }
      }
    }
  }

  .ceiling-content {
    margin-left: 48px;
    height: $nav-height;
    display: flex;
    align-items: center;

    @include media("<tablet") {
      justify-content: space-between;
    }

    &-wrapper {
      display: flex;
      flex: 1;
      align-items: center;
      height: 100%;

      @include media("<tablet") {
        flex-direction: row-reverse;
      }

      @include media(">=tablet") {
        justify-content: space-between;
      }
    }

    .lang {
      margin-right: 24px;
      display: flex;
      align-items: center;
    }
  }

  h1 {
    margin: 0;

    svg,
    img {
      width: 119px;
    }
  }

  .ceiling-menu {
    // margin-left: 2.75rem;
    display: flex;
    align-items: center;
    height: 100%;
  }

  .ceiling-main {
    flex: 1 1 auto;
  }

  .consult-button {
    min-width: 128px;
    height: 100%;
    background-color: $sub-color;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.filter-blur {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -10;
  background-color: rgba($color: #000, $alpha: 0);
  backdrop-filter: blur(10px);
}

.mobile-ceiling {
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 0px 22px;

  &-logo {
    width: 80px;
  }

  &-icon {
    // width: 16px;
    color: $main-color;
  }

}

.phone-wrap {
  flex: 1;
  justify-content: space-between;
  display: flex;
  align-items: center;

  .phone-img {
    width: 14px;
    height: 14px;
    vertical-align: middle;
  }

  .phone-text {
    margin-left: 6px;
    vertical-align: middle;
    cursor: pointer;
  }

  .notice-a {
    margin-right: 20px;

    .notice-img {
      height: 40px;
    }
  }
}