@import 'styles/media';

.comp-scroll-to-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  right: 16px;
  bottom: 24px;
  z-index: 99;
  @include media('<tablet') {
    flex-direction: row;
    right: 8px;
    bottom: 12px;
  }
  .contact-button {
    cursor: pointer;
    .consult-icon {
      width: 48px;
      height: 122px;
      filter: drop-shadow(0 2px 4px rgba(0,0,0,0.12));
      margin-bottom: 8px;
      background-image: url('/static/images/consult-icon.png');
      @include media("<tablet") {
        width: 106px;
        height: 40px;
        margin-bottom: 0;
        background-image: url('/static/images/consult-icon-mobile.png');
      }
    }
  }

  .set-top {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 40px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.08);
    color: rgba($color: #01000E, $alpha: 0.5);
    @include media('<tablet') {
      margin-left: 8px;
    }
  }
}
