@import 'styles/global';

.product-list-wrapper {
  position: relative;
  padding-top: 62px;
  padding-bottom: 72px;
  width: 1220px;
  margin: 0 auto;

  .affix-list {
    position: absolute;
    width: 280px;
  }

  .product-list {
    width: 1220px;
    padding-left: 320px;

    &-group {
      margin-bottom: 64px;

      &-title {
        font-weight: 500;
        font-size: 28px;
        line-height: 40px;
        color: $main-color;
      }

      &-content {
        &-row {
          display: flex;
          flex-wrap: wrap;
          gap: 0px 30px;
          margin: 32px 0px;
        }

        &-item {
          cursor: pointer;
          width: 430px;

          .item-img-wrapper {
            position: relative;
            height: 240px;

            .item-img {
              height: 240px;
              width: 430px;
              border-radius: 8px;
              object-fit: cover;
            }

            .item-play {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translateX(-50%) translateY(-50%);
            }

            .footer-banner {
              position: absolute;
              bottom: 0px;
              width: 100%;
              height: 60px;
              background-color: rgba($color: #fff, $alpha: 0.3);
              border-radius: 0 0 8px 8px;
              backdrop-filter: blur(12px);

              .item-title {
                margin: auto;
                font-weight: 600;
                font-size: 18px;
                color: $main-color;
                line-height: 24px;
              }
            }
          }

          .item-logo {
            margin-top: 18px;
          }

          .item-desc {
            margin-top: 12px;
            font-weight: 400;
            font-size: 14px;
            color: #9196AA;
            line-height: 20px;
          }

          &:hover>.item-detail {
            opacity: 1;
          }

          .item-detail {
            opacity: 0;
            margin-top: 12px;
            color: $sub-color;
            font-weight: 500;
            font-size: 12px;
            color: #0366DD;
            line-height: 16px;
            transition: opacity .3s;
          }
        }
      }
    }
  }
}