@import 'styles/global';

.common-circle-button {
  display: inline-block;
  width: 48px;
  height: 48px;
  text-align: center;
  line-height: 48px;
  border: 1px solid rgba($color: $main-color, $alpha: 0.2);
  border-radius: 48px;
  color: rgba($color: $main-color, $alpha: 0.2);
  cursor: pointer;
  user-select: none;
  &:hover {
    border: 1px solid rgba($color: $main-color, $alpha: 0.4);
    color: $main-color;
  }
  .rotate-prev {
    transform: rotate(180deg);
  }
  .rotate-top {
    transform: rotate(-90deg);
  }
  .rotate-bottom {
    transform: rotate(90deg);
  }
  .block {
    display: block;
  }
}
