video::-webkit-media-controls-start-playback-button {
  display: none !important;
}

.video-player-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh;
  background-color: rgba(47, 60, 95, .1);
  z-index: 99;
}

.video-player-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  max-width: 90vw;
  height: 25rem;
  max-height: 92vh;
  background-color: #fff;
  box-shadow: rgba(255, 255, 255, .5) 0 0 .5rem;
  transform: translateX(-50%) translateY(-50%);

  .video-player {
    width: 45rem;
    height: 100%;
  }

  .video-player-side {
    font-size: .8rem;
    width: 15rem;
    height: 100%;
    padding: 1rem;

    .video-player-title {
      font-size: .9rem;
      line-height: 1.1rem;
      color: #2F3C5F;
      margin-bottom: .4rem;
    }

    .video-player-desc {
      color: #919BAF;
    }
    .video-player-list {
      li {
        display: flex;
        justify-content: space-between;
        color: #919BAF;
        margin: .8rem 0;
        cursor: pointer;

        &.active {
          color: #3c9fdb !important;
        }
      }
    }
  }

  .video-player-close {
    position: absolute;
    top: -.6rem;
    right: -.6rem;
    width: 1.2rem;
    height: 1.2rem;
    line-height: .9rem;
    color: #fff;
    text-align: center;
    background-color: #3c9fdb !important;
    border: #fff solid 2px;
    border-radius: 50%;
    cursor: pointer;
    transition: transform .25s;

    &:hover {
      transform: rotate(360deg);
    }
  }
}

.video-player-mask.mobile {
  background-color: #fff;

  .video-player-wrapper {
    position: static;
    display: block;
    width: 100%;
    max-width: 100%;
    transform: none;

    .video-player {
      width: 100%;
      height: auto;
    }

    .video-player-side {
      width: 100%;
      height: auto;
      font-size: .9rem;

      .video-player-title {
        font-size: 1rem;
        margin-bottom: .8rem;
      }

      .video-player-list {
        display: flex;

        li {
          width: 2rem;
          height: 2rem;
          margin-right: 1rem;
          line-height: 2rem;
          text-align: center;
          background-color: rgba(234, 234, 240, .6);
          border-radius: .25rem;

          &.active {
            .video-player-list-name {
              display: none;
            }
          }

          .video-player-list-name {
            display: block;
            width: 100%;
          }

          .equilizer {
            margin: auto;
          }
        }
      }
    }
  }
}

.equilizer {
  display: flex;
  justify-content: space-between;
  width: 12PX;
  height: 12PX;
  transform: rotate(180deg);

  span {
    display: block;
    width: 2PX;
    background-color: #3c9fdb !important;
    border-radius: 1PX;
    animation: equalize 3.5s 0s infinite;

    &:nth-child(1) {
      animation-delay: -1.9s;
    }

    &:nth-child(2) {
      animation-delay: -2s;
    }

    &:nth-child(3) {
      animation-delay: -2.3s;
    }
  }
}

@keyframes equalize {
  0% {
    height: 75%;
  }
  4% {
    height: 62.5%;
  }
  8% {
    height: 50%;
  }
  12% {
    height: 37.5%;
  }
  16% {
    height: 25%;
  }
  20% {
    height: 37.5%;
  }
  24% {
    height: 50%;
  }
  28% {
    height: 12.5%;
  }
  32% {
    height: 50%;
  }
  36% {
    height: 75%;
  }
  40% {
    height: 25%;
  }
  44% {
    height: 50%;
  }
  48% {
    height: 87.5%;
  }
  52% {
    height: 37.5%;
  }
  56% {
    height: 12.5%;
  }
  60% {
    height: 37.5%;
  }
  64% {
    height: 62.5%;
  }
  68% {
    height: 75%;
  }
  72% {
    height: 87.5%;
  }
  76% {
    height: 100%;
  }
  80% {
    height: 87.5%;
  }
  84% {
    height: 75%;
  }
  88% {
    height: 62.5%;
  }
  92% {
    height: 75%;
  }
  96% {
    height: 87.5%;
  }
  100% {
    height: 100%;
  }
}

/** player styles **/
.prism-player {
  .prism-controlbar {
    backdrop-filter: blur(6px);
  }

  .prism-liveshift-progress .prism-progress-loaded,
  .prism-player .prism-progress .prism-progress-loaded {
    background-color: #b8c1ce !important;
  }

  .prism-liveshift-progress .prism-progress-played,
  .prism-progress .prism-progress-played {
    background-color: #fff !important;
  }
}
