@import 'styles/global';
@import 'styles/media';

.mobile-modal-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  animation: fade-in ease-in-out 0.3s;
  width: 100%;
  height: 100%;
  border-radius: 0px;
  margin-top: 0px;
  .comp-modal-header-shadow {
    box-shadow: 0 1px 6px 0 rgba(0,0,0,0.08);
  }
  .comp-modal-header {
    position: relative;
    width: 100%;
    height: 44px;
    padding: 12px 20px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    color: #01000E;
    line-height: 24px;

    .comp-modal-operation {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;
      line-height: 20px;
      font-size: 14px;
      color: $main-color;
      cursor: pointer;
    }
  }
  .comp-modal-content {
    height: 0;
    flex: 1 1 auto;
    overflow-y: auto;
    padding: 16px 20px;
    .mobile-menu-group-name {
      font-weight: 500;
      font-size: 12px;
      color: #9196AA;
      line-height: 16px;
      margin-bottom: 12px;
      margin-left: 12px;
    }
    .mobile-menu-group {
      display: flex;
      flex-direction: column;
      margin-bottom: 32px;
      &-item:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      &-item:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
      &-item:not(:last-child)::after {
        content: '';
        display: block;
        padding: 0px 12px;
        border-bottom: 1px solid #E7E9F0;
        width: calc(100% - 48px);
        position: absolute;
        bottom: 0px;
      }
      &-item {
        position: relative;
        background-color: #F5F5F7;
        padding: 14px 12px;
        font-weight: 400;
        font-size: 14px;
        color: $main-color;
        line-height: 20px;
        display: flex;
        justify-content: space-between;
        .i-icon {
          color: $gray-color;
          text-align: right;
          margin-right: 0px;
        }
      }
    }
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateX(100%) ;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.modal-open {
  overflow: hidden;
}
