@import 'styles/media';
.about-content {
  width: 720px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  @include media("<tablet") {
    width: auto;
  }
  .about-logo {
    margin-top: 64px;
    @include media("<tablet") {
      margin-top: 8px;
    }
  }
  .rich-text {
    margin-top: 20px;
    width: 720px;
    @include media("<tablet") {
      width: auto;
      margin-top: 40px;
    }
  }
}
