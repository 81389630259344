@import 'styles/media';
@import 'styles/global';

@include media('<tablet') {
  .video-black {
    background-color: #000;
  }
}

.video-modal-wrapper {
  position: absolute;
  width: 80%;
  height: 80%;
  left: 10%;
  top: 10%;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08);
  border: 8px #fff solid;

  @include media('<tablet') {
    width: 100vw;
    height: 100vh;
    left: 0%;
    top: 0%;
    border-radius: 0px;
    box-shadow: none;
    border: none;
  }

  video {
    width: 100%;
    height: 100%;
    background-color: #000;
  }

  .video-close {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 120px;
    height: 40px;
    line-height: 40px;
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .video-close-mobile {
    @extend .video-close;
    width: 32px;
    height: 32px;
    top: 6px;
    left: 20px;
    background-color: $main-color;
    border-radius: 4px;

  }
}