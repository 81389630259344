.rc-tooltip.rc-tooltip-zoom-appear,
.rc-tooltip.rc-tooltip-zoom-enter {
  opacity: 0;
}

.rc-tooltip.rc-tooltip-zoom-enter,
.rc-tooltip.rc-tooltip-zoom-leave {
  display: block;
}

.rc-tooltip-zoom-enter,
.rc-tooltip-zoom-appear {
  opacity: 0;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  animation-play-state: paused;
}

.rc-tooltip-zoom-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
  animation-play-state: paused;

  &.rc-tooltip-zoom-leave-active {
    animation-name: rcToolTipZoomOut;
    animation-play-state: running;
  }
}

.rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active,
.rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
  animation-name: rcToolTipZoomIn;
  animation-play-state: running;
}

@keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
}

@keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
}

.rc-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  font-size: 12px;
  line-height: 1.5;
  opacity: 0.9;

  &-hidden {
    display: none;
  }

  &-placement-top,
  &-placement-topLeft,
  &-placement-topRight {
    padding: 5px 0 9px 0;

    &-arrow {
      bottom: 4px;
      margin-left: -5px;
      border-width: 5px 5px 0;
      border-top-color: #373737;
    }
  }

  &-placement-top &-arrow {
    left: 50%;
  }

  &-placement-topLeft &-arrow {
    left: 15%;
  }

  &-placement-topRight &-arrow {
    right: 15%;
  }

  &-placement-right,
  &-placement-rightTop,
  &-placement-rightBottom {
    padding: 0 5px 0 9px;

    &-arrow {
      left: 4px;
      margin-top: -5px;
      border-width: 5px 5px 5px 0;
      border-right-color: #373737;
    }
  }

  &-placement-right &-arrow {
    top: 50%;
  }

  &-placement-rightTop &-arrow {
    top: 15%;
    margin-top: 0;
  }

  &-placement-rightBottom &-arrow {
    bottom: 15%;
  }

  &-placement-bottom,
  &-placement-bottomLeft,
  &-placement-bottomRight {
    padding: 9px 0 5px 0;

    &-arrow {
      top: 4px;
      margin-left: -5px;
      border-width: 0 5px 5px;
      border-bottom-color: #373737;
    }
  }

  &-placement-bottom &-arrow {
    left: 50%;
  }
  &-placement-bottomLeft &-arrow {
    left: 15%;
  }
  &-placement-bottomRight &-arrow {
    right: 15%;
  }

  &-placement-left,
  &-placement-leftTop,
  &-placement-leftBottom {
    padding: 0 9px 0 5px;

    &-arrow {
      right: 4px;
      margin-top: -5px;
      border-width: 5px 0 5px 5px;
      border-left-color: #373737;
    }
  }

  &-placement-left &-arrow {
    top: 50%;
  }
  &-placement-leftTop &-arrow {
    top: 15%;
    margin-top: 0;
  }
  &-placement-leftBottom &-arrow {
    bottom: 15%;
  }

  &-inner {
    padding: 8px 10px;
    color: #fff;
    text-align: left;
    text-decoration: none;
    background-color: #373737;
    border-radius: 6px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
    min-height: 34px;
  }

  &-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
}
