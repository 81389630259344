@import 'styles/global';
@import 'styles/media';
.consult {
  width: 100%;
  height: 280px;
  background-image: url('/static/images/consult.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  .content {
    padding-top: 62px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include media('<tablet') {
      padding-top: 84px;
    }
  }
  .button {
    display: flex;
    justify-content: center;
    margin-top: 48px;
    @include media('<tablet') {
      margin-top: 16px;
    }
    .icon {
      color: $sub-color !important;
      opacity: 0.5;
    }
  }
}
.detail-consult {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 148px;
  width: 100%;
  background-image: url('/static/images/detail-consult.png');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}
