@import 'styles/global';
@import 'styles/media';

.plate-demand {
  @include media("<tablet") {
    padding: 0px 20px;

    .demand-divider {
      width: 100%;
      margin: 40px 0px;
    }
  }

  .plate-main-title {
    padding-top: 72px;

    @include media("<tablet") {
      padding-top: 40px;
    }
  }

  .plate-demand-wrapper {
    max-width: 1240px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 64px 40px;
    margin: 72px auto;

    @include media("<tablet") {
      display: flex;
      flex-direction: column;
      margin-top: 16px;
      row-gap: 8px;
      column-gap: 0px;
      margin-bottom: 0px;
    }

    .plate-demand-item {
      // width: 386px;
      display: flex;

      @include media("<tablet") {
        background-color: #FAFAFA;
        border-radius: 8px;
        padding: 24px 20px;

        &>img {
          width: 20px;
          height: 20px;
        }
      }

      &-text {
        margin-left: 12px;

        @include media("<tablet") {
          margin-left: 8px;
        }

        .plate-demand-item-title {
          font-weight: 600;
          font-size: 18px;
          color: $main-color;
          line-height: 24px;

          @include media("<tablet") {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
          }
        }

        .plate-demand-item-desc {
          margin-top: 8px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $gray-color;

          @include media("<tablet") {
            font-weight: 300;
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
  }
}

.plate-advantage {
  background-color: #F5F6F7;

  @include media("<tablet") {
    background-color: transparent;
  }

  .plate-advantage-title {
    padding-top: 64px;

    @include media("<tablet") {
      padding-top: 0px;
    }
  }

  .plate-advantage-wrapper {
    max-width: 1240px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 64px 40px;
    margin: 0 auto;
    padding: 64px 0px;

    @include media("<tablet") {
      padding: 0px 20px;
      padding-top: 16px;
      display: flex;
      flex-direction: column;
      gap: 24px 0px;
    }

    .plate-advantage-item {

      // width: 280px;
      @include media("<tablet") {
        &>img {
          width: 20px;
          height: 20px;
        }
      }

      &-title {
        margin-top: 16px;
        font-weight: 600;
        font-size: 18px;
        color: $main-color;
        line-height: 24px;

        @include media("<tablet") {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          margin-top: 8px;
        }
      }

      &-desc {
        margin-top: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $gray-color;

        @include media("<tablet") {
          font-weight: 300;
          font-size: 12px;
          line-height: 16px;
          margin-top: 4px;
        }
      }
    }
  }

}

.plate-values {
  height: 868px;
  overflow: hidden;

  @include media("<tablet") {
    height: auto;
  }

  &-title {
    margin: 64px 0px;

    @include media("<tablet") {
      margin-top: 40px;
      margin-bottom: 16px;
    }
  }

  &-wrapper {
    display: flex;
    height: 688px;
    max-width: 1240px;
    margin: 0 auto;
    position: relative;

    @include media("<tablet") {
      height: auto;
      max-height: 540px;
      min-height: 500px;
      padding: 0px 20px;
    }

    .values-mask {
      pointer-events: none;
      position: absolute;
      bottom: 0px;
      height: 300px;
      width: 100%;
      background-image: linear-gradient(#fff0, #fff6, #fffa, #ffff);
    }

    .carousel-side {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 48px;

      .side-circles {
        height: 300px;
      }

      .side-buttons {
        display: flex;
        flex-direction: column;
        gap: 32px;
      }
    }

    @include media("<tablet") {
      .slick-track {
        display: flex;
        align-items: stretch;

        .slick-slide {
          float: none;
          height: auto;

          >div {
            display: flex;
            height: 100%;

            main {
              display: flex;
              flex-direction: column;
              height: 100%;

              .main-content-wrap {
                flex: 1;
                height: 100%;
              }
            }
          }
        }
      }
    }

    .carousel-content {
      margin-left: 60px;
      display: inline-block;
      flex: 1;

      @include media("<tablet") {
        margin-left: 0px;

        .mobile-pagination {
          margin: 0 auto;
          justify-content: center;
          margin-top: 16px;
          margin-bottom: 40px;
        }
      }

      .main-pic-wrapper {
        .pic-content {
          display: flex !important;
          justify-content: center;
          align-items: center;
          width: 720px !important;
          height: 592px;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }

      .main-content-wrapper {
        width: 440px;

        @include media("<tablet") {
          width: calc(100vw - 40px);
        }

        .main-content {
          border-radius: 8px;
          margin-left: 12px;
          height: 440px;

          @include media("<tablet") {
            height: auto;
            margin-left: 0px;
          }

          cursor: pointer;

          main {
            padding: 40px 60px;

            @include media("<tablet") {
              padding: 0px;
            }

            .main-content-wrap {
              @include media("<tablet") {
                background-image: linear-gradient(180deg, #F1F7FF 0%, #FFFFFF 100%);
                border-radius: 0 0 8px 8px;
                padding: 24px;
              }
            }

            .main-content-img {
              width: 100%;
            }

            .main-content-title {
              font-weight: 600;
              font-size: 18px;
              color: $main-color;
              line-height: 24px;

              @include media("<tablet") {
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
              }
            }

            .main-content-desc {
              margin-top: 8px;
              font-weight: 400;
              font-size: 14px;
              color: $gray-color;

              @include media("<tablet") {
                font-weight: 300;
                line-height: 20px;
              }
            }

            .main-content-detail {
              margin-top: 24px;
              font-weight: 600;
              font-size: 14px;
              color: #01000E;
              line-height: 20px;

              @include media("<tablet") {
                margin-top: 16px;
              }
            }

            .main-content-detail-item {
              display: flex;
              align-items: center;
              margin-top: 8px;
              font-weight: 400;
              font-size: 14px;
              color: #040411;
              line-height: 20px;

              >span {
                margin-left: 4px;
              }
            }
          }
        }

        .main-content-active {
          background-image: linear-gradient(90deg, #F1F7FF 0%, rgba(255, 255, 255, 0.00) 100%);
          box-shadow: -3px 0 0 0 $sub-color;

          @include media("<tablet") {
            background-image: linear-gradient(180deg, #F1F7FF 0%, #FFFFFF 100%);
            box-shadow: 0 2px 0 0 $sub-color;
            margin-bottom: 2px;
          }
        }
      }
    }
  }

  .ant-tabs-nav {
    max-width: 1240px;
    width: 100%;
    margin: 0 auto 16px auto;
    padding: 0 16px;

    &::before {
      border-bottom: 0;
    }
  }

  .ant-tabs-nav-wrap {
    justify-content: center;

    @include media("<tablet") {
      justify-content: flex-start;
    }
  }

  .ant-tabs-nav-operations {
    display: none !important;
  }

  .ant-tabs-tab-btn {
    img {
      height: 20px;
      width: 20px;
      vertical-align: middle;
      margin-right: 8px;
    }
  }

  .fun-tab-name {
    font-size: 16px;
    font-weight: 600;
    vertical-align: middle;
  }

  .plate-core-title {
    margin: 64px 0px 8px 0;

    @include media("<tablet") {
      margin-top: 40px;
      margin-bottom: 16px;
    }
  }

  .blue-print-wraper {
    max-width: 1240px;
    margin: 0 auto;

    @include media("<tablet") {
      padding: 0 20px;
    }
  }
}

.plate-scene {
  background-color: #E6ECF3;
  margin-top: 72px;

  .plate-main-title {
    padding-top: 64px;
    padding-bottom: 32px;

    @include media("<tablet") {
      padding-top: 40px;
      padding-bottom: 16px;
    }
  }

  .scene-content-wrapper {
    .scene-content {
      height: 128px;

      @include media("<tablet") {
        height: auto;
      }

      main {
        height: 100%;
        margin: 4px;
        border-radius: 8px;
        background-color: #fff;
        opacity: 0.5;
        display: flex;

        @include media("<tablet") {
          height: 150px;
          padding: 20px;
        }

        .scene-logo {
          width: 128px;
          justify-content: center;
          align-items: center;

          @include media("<tablet") {
            width: 24px;
            height: 24px;

            &>img {
              width: 24px;
              height: 24px;
            }
          }
        }

        .scene-text {
          margin: 28px 0px;
          padding: 0px 24px;
          border-left: rgba($color: $main-color, $alpha: 0.1) 1px solid;
          flex: 1 1 auto;
          width: 100px;

          @include media("<tablet") {
            margin: 0px;
            padding: 0px;
            padding-left: 20px;
            border-left: 0;
          }

          .scene-content-title {
            font-weight: 600;
            font-size: 18px;
            color: $main-color;
            line-height: 24px;

            @include media("<tablet") {
              font-weight: 500;
              font-size: 16px;
              line-height: 20px;

            }
          }

          .scene-content-desc {
            font-weight: 400;
            font-size: 14px;
            color: $gray-color;
            line-height: 20px;
            white-space: pre-wrap;

            @include media("<tablet") {
              margin-top: 4px;
              font-weight: 300;
              font-size: 14px;
              line-height: 16px;
              -webkit-line-clamp: 5 !important;
            }
          }
        }
      }
    }

    .scene-content-active {
      main {
        opacity: 1;
      }
    }
  }

  .scene-carousel-footer {
    height: 112px;
    padding: 32px;
    gap: 0px 12px;
    width: 860px;

    .scene-pagination {
      flex: 1 1 auto;
      justify-content: center;
    }

    .scene-circle {
      flex: 0 0 auto;
    }
  }
}

.plate-cases {
  margin-top: 72px;
  padding-top: 102px;
  padding-bottom: 72px;
  background-color: #f5f6f7;

  @include media('<tablet') {
    padding: 40px 0px;
  }

  &-content {
    margin: 0 auto;
    margin-top: 32px;
    max-width: 1008px;
    display: grid;
    grid-gap: 24px 24px;
    grid-template-columns: repeat(3, 1fr);

  }

  &-list {
    margin-top: 32px !important;
  }
}

.case-switch {
  display: flex;
  justify-content: center;

  &-list {
    display: flex;
    justify-content: left;
    max-width: 100%;
    overflow: auto;

    @include media('<tablet') {
      padding: 0 6px;
    }
  }

  .case-switch-wrapper {
    margin: 0 auto;
    max-width: 1032px;
    position: relative;
  }

  .card {
    background-color: #fff;
    border-radius: 8px;
    width: 320px;
    height: 100%;
    margin: 0px 6px;
    transition: opacity .3s;

    @include media('>=tablet') {
      margin: 0px 12px;

      &:hover .item-detail {
        opacity: 1;
      }
    }

    &:hover {
      box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
    }

    &-img {
      border-radius: 8px 8px 0px 0px;
      object-fit: cover;
      width: 320px;
      height: 160px;
    }

    &-content {
      padding: 24px;
      color: $main-color;

      .title {
        min-height: 40px;
        margin-top: 6px;
        font-size: 14px;
        line-height: 20px;
      }

      .card-logo {
        height: 24px;
      }

      .item-detail {
        opacity: 0;
        margin-top: 24px;
        color: $sub-color;
        font-weight: 500;
        font-size: 12px;
        color: $sub-color;
        line-height: 16px;
        transition: opacity .3s;
      }
    }

  }

  .case-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -80px;
  }

  .case-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -80px;
  }
}

.blue-print-img {
  width: 100%;
  margin: 0 auto;
}

.carousel-content-detail {
  max-width: 440px;
  margin-left: 60px;
  display: inline-block;
}

.pic-wrap {
  margin-left: 20px !important;
}