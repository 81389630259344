.video-icon-wraper {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: calc(50% - 16px);
  width: 100%;
  justify-content: center;
  max-width: 800px;
}

.media-img {
  border-radius: 8px !important;
}