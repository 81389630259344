@import 'styles/global';
@import 'styles/media';

.supplier-banner {
  width: 100%;
  height: 420px;
  position: relative;

  .supplier-banner-background {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  .supplier-banner-inner {
    width: 1200px;
    height: 100%;
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .supplier-banner-inner-title {
      font-family: PingFang SC;
      font-size: 32px;
      font-weight: 500;
      line-height: 40px;
      text-align: center;
      color: #ffffff;
    }

    .supplier-banner-inner-desc {
      font-family: PingFang SC;
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
      text-align: center;
      color: #ffffff;
      margin-top: 8px;
    }

    .supplier-banner-inner-btn {
      margin-top: 24px;
      width: 144px;
      height: 40px;
      padding: 8px 40px 8px 40px;
      gap: 4px;
      border-radius: 25px;
      border: none;
      //styleName: 非加粗/16pt;
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: #324181;
    }

  }
}

.supplier-settle {
  position: relative;
  width: 100%;
  height: 240px;

  .supplier-settle-background {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  .supplier-settle-inner {
    width: 1200px;
    height: 100%;
    position: relative;
    margin: 0 auto;
    padding-top: 40px;

    .supplier-settle-title,
    .supplier-settle-content {
      width: 100%;
    }

    .supplier-settle-protocol {
      position: absolute;
      text-decoration: underline;
      bottom: 50px;
      right: 470px;
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;

    }

    .supplier-settle-pay {
      text-decoration: underline;
      position: absolute;
      bottom: 50px;
      right: 223px;
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
    }
  }
}

.supplier-settle-wrap {
  background-color: #2e363e;

  .supplier-settle-wrap-inner {
    margin: 0 auto;
    width: 1200px;
  }

  .supplier-settle-data {
    width: 100%;
    height: 250px;
  }

  .supplier-settle-detail {
    width: 100%;

  }
}