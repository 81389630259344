@import 'styles/global';
@import 'styles/media';

.cursor-circle-wrapper {
  display: flex;
  gap: 10px 10px;
  @include media('<tablet') {
    gap: 6px 6px;
  }
  &-vertical {
    flex-direction: column;
  }
  .cursor-circle {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: rgba($color: $main-color, $alpha: 0.08);
    cursor: pointer;
    @include media('<tablet') {
      width: 6px;
      height: 6px;
      border-radius: 6px;
    }
  }
  .cursor-circle-active {
    background-color: $sub-color;
  }

}
