@import 'styles/global';
@import 'styles/media';

@font-face {
  font-family: digital;
  src: url('/static/font/digital-regular.ttf');
}

.activity-detail {
  max-width: 1000px;
  margin: 0 auto;
  padding-top: $header-height;

  @include media('<tablet') {
    padding: 0px 20px;
  }

  .content-detail {
    padding-bottom: 0;
  }

  .rich-text-wrapper {
    margin-bottom: 0;
  }

  .detail-title {
    margin-top: 48px;
    font-weight: 500;
    font-size: 32px;
    color: $main-color;
    line-height: 40px;
    text-align: center;

    @include media('<tablet') {
      margin-top: 24px;
      font-size: 24px;
      line-height: 32px;
    }
  }

  .detail-time {
    display: inline-block;
    margin: 0 auto;
    margin-top: 14px;
    font-weight: 600;
    font-size: 16px;
    color: $sub-color;
    line-height: 28px;
    text-align: center;
    border-radius: 8px;
    background-color: #EDF5FF;
    padding: 2px 20px;

    @include media('<tablet') {
      margin-left: 0px;
      margin-right: auto;
      font-size: 13px;
    }
  }

  .detail-banner {
    margin: 32px 0px;
    position: relative;
    display: flex;

    .banner-main {
      position: relative;
      width: 100%;

      &.pl100 {
        @include media('>tablet') {
          padding-left: 100px;
        }
      }

      &>img {
        border-radius: 8px 0 0 8px;
        width: 800px;
        height: 100%;


        @include media('<tablet') {
          width: 100%;
          height: auto;
          border-radius: 8px;
          height: 155px;
        }
      }

      &>video {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        width: 800px;
        height: 450px;

        @include media('<tablet') {
          width: calc(100vw - 40px);
          height: auto;
          border-radius: 8px;
        }
      }

      &-mask {
        flex-direction: column;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 800px;
        height: 450px;
        background-color: rgba($color: #000000, $alpha: 0.6);

        &>span {
          margin-top: 8px;
          color: #fff;
          font-weight: 300;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }

    .banner-side {
      padding: 24px 16px;
      // height: 100%;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      display: flex;
      flex-direction: column;
      background-color: #F5F6F7;

      .side-intro {
        height: 150px;
        flex: 0 0 auto;

        >img {
          width: 168px;
          height: 95px;
        }

        .side-tip {
          margin-top: 24px;
          margin-bottom: 16px;
          font-size: 14px;
          color: $main-color;
          line-height: 20px;
        }
      }

      .time-line {
        flex: 1 1 auto;
        height: 0px;
        padding: 16px 0px;
        overflow-y: auto;

        .time-line-item {
          &-header {
            display: flex;
            align-items: center;

            .circle {
              width: 6px;
              height: 6px;
              border-radius: 6px;
              background-color: $sub-color;
            }

            .time {
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              color: $main-color;
              margin-left: 16px;
              font-family: digital;
            }
          }

          .dash-block {
            border-left: dashed 1px $sub-color;
            padding: 8px 0px 16px 19px;
            font-weight: 400;
            font-size: 12px;
            color: $gray-color;
            line-height: 16px;
            margin-left: 2px;
          }
        }

        .time-line-item:last-child {
          .dash-block {
            border-left: none;
          }
        }
      }
    }
  }

  .detail-tabs {
    height: 52px;
    width: 100%;
    background-color: #fff;
    background-image: linear-gradient(270deg, rgba(245, 246, 247, 0.00) 0%, #F5F6F7 52%, rgba(245, 246, 247, 0.00) 100%);

    @include media('<tablet') {
      justify-content: space-between;
    }

    a {
      cursor: pointer;
      padding: 0px 46px;
      color: $main-color;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      @include media('<tablet') {
        color: $gray-color;
        font-size: 14px;
        padding: 0px;

        .tabs-icon {
          margin-right: 4px;
          font-size: 14px;
        }
      }

      .tabs-icon {
        color: $gray-color;
      }
    }

    .active {
      color: $sub-color;

      .tabs-icon {
        color: $sub-color;
      }
    }
  }

  .detail-intro {
    &-title {
      margin-top: 32px;
      margin-bottom: 24px;
    }

    &-content {
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      text-align: left;
      color: $gray-color;
      padding: 32px 140px;
      background-image: url('/static/images/activity-intro.png');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;

      @include media('<tablet') {
        padding: 32px 20px;
        border-radius: 8px;
      }
    }
  }

  .detail-agenda {
    &-title {
      margin-top: 32px;
      margin-bottom: 24px;

      @include media('<tablet') {
        margin-top: 40px;
      }
    }

    &-content {
      border: 1px solid #E7E9F0;
      border-radius: 8px;
      padding: 34px 0px 14px 0px;
      padding-left: 24px;

      @include media('<tablet') {
        border: none;
        padding: 0px;
      }

      .time-line-item {
        display: flex;

        &-header {
          display: flex;

          @include media('<tablet') {
            position: relative;
            width: auto;
          }

          .time {
            text-align: right;
            width: 264px;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            color: $main-color;
            font-family: digital;

            @include media('<tablet') {
              width: 264px;
              position: absolute;
              left: 50px;
              top: -10px;
              text-align: left;
              color: $sub-color;
            }
          }

          .dash-content {
            position: relative;
            top: 10px;
            display: flex;
            flex-direction: column;

            @include media('<tablet') {
              top: 0px;
            }

            .circle {
              margin-left: 32px;
              width: 8px;
              height: 8px;
              border-radius: 8px;
              background-color: $sub-color;
            }

            .dash {
              position: relative;
              right: 4px;
              height: 0px;
              flex: 1 1 auto;
              border-right: 1px dashed $sub-color;
              margin: 8px 0px;
            }
          }

        }

        &-content {
          width: 100%;
          margin-left: 32px;
          position: relative;
          top: -10px;

          @include media('<tablet') {
            top: 20px;
            margin-left: 12px;
          }

          .content-title {
            line-height: 20px;
            font-weight: 500;
            font-size: 14px;
            color: $main-color;
          }

          .content-intro {
            display: flex;
            align-items: center;
            margin-top: 10px;

            &>img {
              box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
              border: 1px solid #FFFFFF;
              border-radius: 20px;
              width: 20px;
              height: 20px;
            }

            .intro {
              font-weight: 400;
              font-size: 12px;
              color: $main-color;
              line-height: 16px;
              margin-left: 4px;
              min-width: 40px;
            }

            .position {
              font-weight: 300;
              font-size: 12px;
              color: $gray-color;
              line-height: 16px;
            }
          }

          .divider {
            margin: 24px 0px;

            @include media('<tablet') {
              opacity: 0;
            }
          }
        }
      }

      .time-line-item:last-child {
        .time-line-item-header {
          .dash-content {
            .dash {
              border-right: none !important;
            }
          }
        }
      }
    }
  }

  .detail-guest {
    margin-bottom: 32px;

    &-title {
      margin-top: 32px;
      margin-bottom: 24px;

      @include media('<tablet') {
        margin-bottom: 40px;
      }
    }

    @include media('<tablet') {
      .grid-col-3 {
        grid-template-columns: repeat(2, 1fr) !important;
      }
    }

    .guest-wrapper {
      display: grid;
      row-gap: 12px;
      column-gap: 12px;

      @include media('>=tablet') {
        grid-template-columns: repeat(4, 25%);
      }


      @include media('<tablet') {
        row-gap: 8px;
        column-gap: 8px;
      }

      .guest-item {
        // width: 241px;
        height: 224px;
        background-color: #F5F6F7;
        border-radius: 8px;

        @include media('<tablet') {
          height: auto;
          padding: 24px;
        }

        &>img {
          display: block;
          margin: 0 auto;
          margin-top: 32px;
          border-radius: 100px;
          width: 100px;
          height: 100px;

          @include media('<tablet') {
            width: 60px;
            height: 60px;
            margin-top: 0px;
          }
        }

        &>div {
          text-align: center;
        }

        .name {
          margin-top: 16px;
          font-weight: 600;
          font-size: 16px;
          color: $main-color;
          line-height: 24px;

          @include media('<tablet') {
            font-size: 12px;
            margin-top: 12px;
            line-height: 16px;
          }
        }

        .position {
          margin-top: 4px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $gray-color;

          @include media('<tablet') {
            margin-top: 2px;
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
  }

  .affix-block {
    height: 0px;
    position: relative;
    bottom: 70px;
  }

  .detail-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 0.6rem;
    text-align: center;
    z-index: 100;
  }

  .content-detail {
    padding-top: 1rem;
    margin-bottom: 2rem;

    @include media('>=tablet') {
      width: 800px;
    }
  }
}

.review-list-row {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 32px 0px;

  &.smaller {
    justify-content: center;
  }

  @include media('<tablet') {
    padding: 0
  }

  &-item {
    width: 33.33%;
    position: relative;

    @include media('>tablet') {
      padding: 0;

      &.bigger {
        width: 50%;
        max-width: 500px;

        .review-list-row-item-img {
          width: 488px;
          height: 242px;
        }

        .review-list-row-item-img-wrap {
          width: 488px;
          height: 242px;
        }
      }
    }

    @include media('<tablet') {
      width: 50%;
      display: flex;
      flex-direction: column;
      padding-top: 16px;
      padding-bottom: 16px;

      &.mobile-bigger {
        width: 100%;
        padding: 0 !important;

        .review-list-row-item-img {
          width: 100%;
          height: 186px;
        }

        .review-list-row-item-img-wrap {
          width: 100%;
          height: 186px;
        }
      }

      &:nth-child(2n) {
        padding-left: 6px;
      }

      &:nth-child(2n + 1) {
        padding-right: 6px;
      }
    }

    &:hover .news-list-row-item-title {
      color: $sub-color;
    }

    &-img-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      @include media('>tablet') {
        width: 317px;
        height: 158px;
      }

      @include media('<tablet') {
        height: 100px;
      }
    }

    &-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 317px;
      height: 158px;
      display: inline-block;
      border-radius: 8px;
      object-fit: cover;

      @include media('<tablet') {
        width: 100%;
        height: 100px;
        flex: 0 0 auto;
        margin-left: auto;
      }
    }

    &-text {
      height: 100px;
      overflow: hidden;

      @include media('<tablet') {
        flex: 1 1 auto;
        min-height: 48px;
        width: 100%;
        padding-right: 10px;
      }
    }

    &-title {
      margin-top: 16px;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #2A2B2E;

      @include media('<tablet') {
        margin-top: 0px;
        font-size: 16px;
        line-height: 24px;
      }

    }

    &-content {
      margin-top: 4px;
      font-weight: 300;
      font-size: 16px;
      line-height: 22px;
      color: $gray-color;
    }

    &-time {
      margin-top: 24px;
      font-weight: 300;
      font-size: 16px;
      color: #676A76;
      line-height: 16px;

      @include media('<tablet') {
        margin-top: 8px;
        font-size: 12px;
      }
    }

    &-button {
      cursor: pointer;
    }
  }

  .row-line {
    position: absolute;
    bottom: 0;
  }
}