@import './media.scss';
@import 'styles/global';

body {
  @include media('>=tablet') {
    min-width: 1200px;
  }
}

.comp-root {
  @include media('>1440px') {
    padding-left: calc((100% - 1440px) / 2);
    padding-right: calc((100% - 1440px) / 2);
  }

  .comp-title {
    font-size: 1.8rem;
    font-weight: normal;
    color: #2f3c5f;
    text-align: center;
  }
}

.comp-content {
  @include media('<tablet') {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include media('>=tablet') {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  @include media('>=desktop') {
    padding-left: 6rem;
    padding-right: 6rem;
  }
}

.comp-mini-content-wrapper {
  @include media('>=tablet') {
    width: 85%;
    margin: auto;
  }
}

.comp-form-wrapper {
  @include media('>=tablet') {
    width: 60%;
    margin: auto;
  }
}

.no-wrap-single {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-wrap-mutil {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;

  -webkit-box-orient: vertical;

  @include media(">=tablet") {
    -webkit-line-clamp: 4;
  }

  @include media("<=tablet") {
    -webkit-line-clamp: 2;
  }
}


.img-background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.flex-h-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-slide.slick-active {
  z-index: 1;
}

.hidden {
  display: none !important;
}

.hidden-in-mobile {
  @include media('<tablet') {
    display: none !important;
  }
}

.hidden-in-pc {
  @include media('>=tablet') {
    display: none !important;
  }
}

@for $i from 1 through 9 {
  .grid-col-#{$i} {
    grid-template-columns: repeat(#{$i}, 1fr) !important;
  }
}

@for $i from 1 through 9 {
  .line-clamp-#{$i} {
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: #{$i};
  }
}

.pre-line {
  white-space: pre-line;
  text-align: justify;
}

.text-center {
  text-align: center;
}