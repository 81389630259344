.modal {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  bottom: -100%;
  left: 0px;
  animation: fromSky .2s ease-out;
  animation-fill-mode: forwards;

  &-hide {
    animation: leaveSky .1s ease-out;
    animation-fill-mode: forwards;
    bottom: 0;
  }
  &-trans {
    height: 35%;
    background-color: #000;
    opacity: .6;
  }
  &-cont {
    background-color: #fff !important;
    height: 65%;
  }
  &-top{
    padding: 2rem 0rem;
    display: flex;
    flex-direction: row;
    justify-content:center;
    width: 100%;
    position: relative;

    &-title {
      color: #2f3c5f;
      font-size: 1.4rem;
    }
    &-close {
      margin: 0;
      padding: 0;
      font-size: 1.2rem;
      position: absolute;
      right: 0rem
    }
  }
}


@keyframes fromSky {
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 0;
  }
}

@keyframes leaveSky {
  0% {
    bottom: 0;
  }
  100% {
    bottom: -100%;
  }
}