@import 'styles/global';
@import 'styles/media';
.case-show {
  margin: 0 auto;
  position: relative;
  max-width: $normal-width;
  height: 894px;
  background-color: #fff;
  @include media("<tablet") {
    height: 648px;
  }
  .case-wrapper {
    position: relative;
    z-index: 1;

    .desc-wrapper {
      padding-top: 72px;
      @include media("<tablet") {
        padding-top: 64px;
      }
    }
  }
  .label {
    @include media("<tablet") {
      width: 100%;
      overflow: auto;
      padding: 0px 22px;
    }
    .label-wrapper {
      width: 1100px;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      pointer-events: none;
      position: relative;
      z-index: 1;
      @include media("<tablet") {
        width: 660px;
        height: 295px;
      }
      .label-strong {
        font-weight: 600;
        font-size: 20px;
      }
      .tips-header {
        margin-top: 48px;
        font-size: 0px;
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        &>img {
          width: 40px;
          height: 40px;
          box-shadow: 0 2px 16px 0 rgba(0,0,0,0.20);
          border-radius: 20px;

        }
        &>div {
          font-weight: 400;
          font-size: 14px;
          color: #01000E;
          margin: 0px 4px;
        }
      }
      @include media("<tablet") {
        pointer-events: all;
      }
    }
  }

  .static-earth {
    position: absolute;
    top: 150px;
    &>div {
      background-image: url('/static/images/earth.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
      height: 400px;
    }
    padding: 0px 22px;
    width: 100%;
  }
  .earth-wrapper {
    position: absolute;
    top: 240px;
    left: 50%;
    transform: translateX(-50%);
    width: 660px;
    height: 660px;
    user-select: none;
  }
  .wall-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 8.75%;
    @include media("<tablet") {
      padding: 0 22px;
      // min-width: 375px;
    }
    // background-color: $sub-color;
    &::before {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      // z-index: -10;
      background-color: rgba($color: #fff, $alpha: 0.2);
      backdrop-filter: blur(10px);
      @include media("<tablet") {
        display: none;
      }
    }
  }
}
