@import 'styles/media';

.tips-list-title {
  display: flex;
}

.tips-list-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  @include media('<tablet') {
    gap: 8px;
  }
  .tips-list-item {
    font-weight: 400;
    font-size: 14px;
    padding: 12px 24px;
    line-height: 20px;
    border-width: 1px;
    border-style: solid;
    background-color: #fff;
    @include media('<tablet') {
      padding: 8px 12px;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }
  .item-active {
    font-weight: 500;
    color: #fff;
  }
  .border-left {
    border-radius: 24px;
    border-bottom-left-radius: 0px;

  }
  .border-right {
    border-radius: 24px;
    border-bottom-right-radius: 0px;
  }
}
