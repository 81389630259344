@import 'styles/global';
@import 'styles/media';

.video-wrapper {
  position: relative;
  vertical-align: middle;
  display: flex;
  min-width: 32px;
  max-width: 120px;
  justify-content: center;
  cursor: pointer;

  .video-circle {
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    background-color: $sub-color;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    z-index: 1;
  }

  .active-circle {
    background-color: $sub-color;
  }

  .active-anim {
    animation: show-video .2s ease-out forwards;
  }

  .active-anim-out {
    animation: hide-video .2s ease-out forwards;
  }

  .active-text {
    overflow: hidden;
    white-space: nowrap;
    flex: 0 0 auto;
    margin-left: -32px;
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 16px;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(12px);
    padding: 8px 0px;
    border-radius: 32px;
    width: 0px;

    @include media('<tablet') {
      width: 100%;
      padding-left: 44px;
      padding-right: 12px;
    }
  }

  @keyframes show-video {
    0% {
      padding-left: 32px;
    }

    100% {
      width: 100%;
      padding-left: 44px;
      padding-right: 12px;
    }
  }

  @keyframes hide-video {
    100% {
      padding-left: 32px;
    }

    0% {
      width: 100%;
      padding-left: 44px;
      padding-right: 12px;
    }
  }
}