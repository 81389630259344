@import 'styles/media';
.overlay-open {
  overflow: hidden;
}
.comp-overlay-none {
  display: none;
}
.comp-overlay-mask {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.2);
}
