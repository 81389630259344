@import 'styles/global';
@import 'styles/media';

.product-banner-wrapper {
  padding-top: 72px;
  background-color: #fff;

  @include media("<tablet") {
    padding-top: 40px;
  }

}

.default-banner-content {
  @include media(">=tablet") {
    padding: 0px 124px !important;
  }

}

.banner-custom-content {
  font-size: 0px;

  .production-image {
    margin-top: 42px;
    margin-bottom: 72px;
    width: 100%;
    max-height: 100%;

    @include media("<tablet") {
      padding-top: 20px;
      width: 100%;
      margin: 0 auto;
    }
  }

  .production-center {
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-title {
      font-weight: 500;
      font-size: 32px;
      line-height: 45px;
      color: #01000E;
    }

    .sub-title {
      margin-top: 8px;
      font-weight: 300;
      font-size: 18px;
      color: $gray-color;
    }
  }
}

.detail-header-wrapper {
  height: 400px !important;
  padding: 0 !important;

  @include media("<tablet") {
    height: 320px !important;
  }

  .title-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 38px;
    padding: 0px 20px;

    .title-content {
      max-width: 640px;
    }
  }
}

.banner-case-detail-wrapper {
  height: 520px !important;
  padding: 0 !important;
  background-color: #fff;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;

  @include media("<tablet") {
    height: 320px !important;
  }

  .title-wrapper {
    padding-top: 194px;
    width: 640px;
    margin: 0 auto;

    @include media("<tablet") {
      width: auto;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 100px;
    }

    .play-text {
      margin-top: 8px;
      font-weight: 300;
      font-size: 12px;
    }
  }

  .banner-case-detail-title {
    font-weight: 500;
    font-size: 32px;
    text-align: center;
    line-height: 40px;

    @include media("<tablet") {
      width: auto;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
  }

  .case-video-icon {
    margin-top: 24px;
  }
}

.plate-detail-wrapper {
  height: 520px !important;
  position: relative;

  @include media("<tablet") {
    height: 320px !important;
    margin-bottom: 72px;
  }

  .title-wrapper {
    margin: 0 auto;
    max-width: 640px;

    @include media("<tablet") {
      padding: 0px 20px;
    }
  }

  .plate-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0 12px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 72px;
    backdrop-filter: blur(12px);

    @include media("<tablet") {
      bottom: -72px;
      height: 72px;
      background-color: #F5F5F7;
    }

    .footer-button {
      min-width: 138px;
      font-size: 14px;
      font-weight: 500;
      min-width: 10em;
      padding-left: 24px;
      padding-right: 24px;
    }
  }
}

.solution-detail-wrapper {
  height: 520px !important;
  position: relative;

  @include media("<tablet") {
    height: 320px !important;
    margin-bottom: 72px;
  }

  .title-wrapper {
    margin: 0 auto;
    max-width: 640px;

    @include media("<tablet") {
      position: absolute;
      right: 20px;
      bottom: 40px;
      left: 20px;
    }
  }

  .solution-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: $main-color, $alpha: 0.2);
    gap: 0 12px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 72px;
    backdrop-filter: blur(12px);

    @include media("<tablet") {
      bottom: -71px;
      background-color: $main-color;
    }

    .footer-button {
      font-size: 14px !important;
      font-weight: 500 !important;
      min-width: 138px;
      padding: 8px 24px;
    }

    .btn-main {
      border: 1px solid #0366DD
    }

    .btn-video {
      margin-right: -60px;
      padding-left: 14px;

      .button-video-circle {
        height: 40px;
        width: 40px;
      }

      .button-video-text {
        line-height: 40px;
        height: 40px;
        min-width: 138px;
      }

      @include media("<tablet") {
        .button-video-circle {
          height: 44px;
          width: 44px;
        }

        .button-video-text {
          line-height: 44px;
          height: 44px;
        }
      }
    }

    .solution-video-icon {
      display: flex;
      cursor: pointer;

      .solution-video-circle {
        background-color: #fff;
        width: 40px;
        height: 40px;
        border-radius: 40px;
      }

      .solution-video-text {
        position: relative;
        left: -40px;
        padding-left: 58px;
        padding-right: 28px;
        font-weight: 500;
        font-size: 14px;
        color: #FFFFFF;
        height: 40px;
        line-height: 40px;
        background-color: $sub-color;
        z-index: -1;
        border-radius: 25px;
      }
    }
  }
}

.pc-banner-operation-wrapper {
  @include media("<tablet") {
    display: none;
  }
}

.mobile-banner-operation-wrapper {
  @include media(">=tablet") {
    display: none;
  }
}