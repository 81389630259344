@import 'styles/global';

.menu-overlay {
  max-width: 96%;

  .hover-sub-menu-col-title {
    @media screen and (max-width: 1400px) {
      width: 144px;
    }

    @media screen and (max-width: 1300px) {
      width: 130px;
    }
  }

}

.menu-overlay-less {
  max-width: 70%;
}

.comp-menu,
.menu-submenu ul {
  padding: 0;
  margin: 0px 24px;
  list-style: none;

  @media screen and (max-width: 1400px) {
    margin: 12px;
  }

  li {
    // margin-left: 48px;
    padding: 0px 24px;
    font-size: 14px;
    font-weight: 400;
    height: 100%;

    @media screen and (max-width: 1400px) {
      padding: 0px 16px;
    }

    @media screen and (max-width: 1000px) {
      padding: 0px 8px;
    }

    &:hover,
    &.hover {
      >.link {
        color: $sub-color !important;
      }
    }

    .link {
      display: flex;
      align-items: center;
      color: $main-color;
      text-decoration: none;
      transition: color .3s;
      height: 100%;

      // div,
      // p {
      //   white-space: nowrap;
      //   overflow: hidden;
      //   text-overflow: ellipsis;
      // }
    }

    span.link {
      cursor: default;
    }
  }
}

.comp-menu {
  height: 3rem;
  display: flex;
  flex-direction: row;

  &.dark {
    >li {
      .link {
        color: #fff;
      }
    }
  }

  li {

    &:hover,
    &.hover {
      &::after {
        margin-left: 0;
        width: 100%;
      }
    }

    &:hover~li::after,
    &.hover~li::after {
      margin-left: 0;
    }

    &::after {
      content: '';
      position: relative;
      display: block;
      width: 0;
      margin: -.1rem 0 0 100%;
      height: .1rem;
      background-color: $sub-color;
      transition: all .4s;
      z-index: 1;
    }

    .link {
      // font-size: .8rem;
      // line-height: 3rem;
      white-space: nowrap;
    }
  }
}

.menu-submenu {
  position: absolute;
  top: 72px;
  left: 48px;
  width: calc(100% - 96px);
  border-radius: 8px;
  height: 0;
  // margin-top: -1px;
  overflow: hidden;
  background-color: #fff;
  transition: height .25s;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08);


  &.show {
    border-top: rgba(206, 205, 215, .3) solid 1px;
    border-bottom: rgba(206, 205, 215, .3) solid 1px;
  }

  ul>li {
    height: auto;
  }

  .link {
    font-size: .8rem;
  }

  p {
    font-size: .7rem;
    color: #40507d;
    margin: .3rem 0 0;
    line-height: 1rem;
    opacity: .75;
  }

  .menu-submenu-content {
    background-color: rgba(246, 246, 248, .6);
  }
}

.menu-submenu-wrapper {
  display: flex;

  .menu-sub {
    width: 14.8rem;
    padding: .6rem 0;
    background-color: #fff;
    border-right: rgba(206, 205, 215, .3) solid 1px;
    border-left: rgba(206, 205, 215, .3) solid 1px;

    li {
      margin: .6rem 0;

      &:hover,
      &.hover {
        .link {
          background-color: rgba(60, 159, 219, .06);

          &::before {
            right: -1.3rem;
          }
        }

        >ul {
          visibility: visible;
          opacity: 1;
          z-index: 1;
        }
      }

      .link {
        position: relative;
        display: flex;
        align-items: center;
        padding-top: .6rem;
        padding-bottom: .6rem;
        border-radius: .4rem;
        transition: all .3s;

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          border-color: transparent;
          background-color: #fff;
        }

        &::before {
          right: -.9rem;
          height: .5rem;
          width: .5rem;
          margin-top: -.25rem;
          transform: rotate(45deg);
          border-top: #f0f0f3 solid .05rem;
          border-right: #f0f0f3 solid .05rem;
          transition: all .35s;
        }

        &::after {
          right: -1rem;
          width: 1rem;
          height: 1rem;
          margin-top: -.5rem;
        }

        img {
          width: 1.2rem;
          height: 1.2rem;
          margin: 0 .6rem 0 .4rem;
        }
      }
    }
  }

  .menu-child {
    display: flex;
    flex-wrap: wrap;
    align-content: start;
    width: calc(100% - 14.8rem);
    height: 100%;
    padding: .8rem 1rem;
    // visibility: hidden;
    // opacity: 0;
    transition: all .35s;
    z-index: 0;

    li {
      width: 33.33%;
      padding: .3rem .25rem;

      .link {
        padding: .5rem .75rem;
        border-radius: .4rem;
        transition: all .3s;

        &:hover {
          background-color: rgba(60, 159, 219, .06);
        }

        p {
          height: 2rem;
        }
      }
    }
  }
}

.menu-card {
  &-header {
    display: flex;
    justify-content: space-between;

    &-title {
      font-size: .8rem;
      color: #2f3c5f;
    }

    &-more {
      font-size: .7rem;
      color: #3c9fdb;

      svg {
        margin-left: .1rem;
      }
    }
  }
}

.menu-cases-wrapper,
.menu-about-wrapper {
  display: none;
  border-left: rgba(206, 205, 215, .3) solid 1px;
  border-right: rgba(206, 205, 215, .3) solid 1px;
  background-color: #fff;
}

.menu-cases-wrapper {
  padding: .8rem 1.6rem;

  &.show {
    display: block;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -.8rem;

    li {
      width: 25%;
      padding: .8rem;

      a {
        position: relative;
        display: block;
        font-size: .7rem;
        color: #2f3c5f;
        width: 100%;
        height: 5rem;
        line-height: 3.2rem;
        text-align: center;
        padding-bottom: 2rem;
        border: rgba(224, 232, 245, .6) solid 1PX;
        border-radius: .4rem;
        background-color: #fff;

        &:hover {
          border-color: #3c9fdb;
        }
      }

      img {
        max-width: 40%;
        max-height: 60%;
        vertical-align: middle;
      }

      span {
        position: absolute;
        bottom: .75rem;
        left: 0;
        width: 100%;
        line-height: 1rem;
      }
    }
  }
}

.menu-about-wrapper {
  &.show {
    display: flex;
  }

  .menu-about-side {
    width: 11.5rem;
    padding: .75rem;

    li {
      font-size: .9rem;
      color: #2f3c5f;
      height: 1.8rem;
      line-height: 1.8rem;
      padding: 0 0 0 20%;
      margin: .6rem 0;
      border-radius: .2rem;
      cursor: pointer;

      &.active {
        color: #3c9fdb;
        background-color: rgba(60, 159, 219, .06);
      }
    }
  }

  .menu-about-content {
    flex: 1;
    padding: .8rem 1rem;
    border-left: #f0f0f3 solid 1PX;
    background-color: rgba(246, 246, 248, .6);

    .menu-card {
      display: none;

      &.show {
        display: block;
      }
    }

    p {
      color: #7e8697;
    }

    ul {
      li {
        padding: 1rem 0;
        border-bottom: rgba(203, 203, 217, .4) solid 1PX;

        &:last-child {
          padding-bottom: 0;
          border-bottom: none;
        }

        a {
          display: flex;
        }

        .item-side {
          width: 3.5rem;
          height: 3.5rem;
          margin-right: .7rem;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          border-radius: .2rem;
        }

        .item-content {
          flex: 1;
          padding-top: .1rem;

          div,
          p {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }

          div {
            font-size: .7rem;
            color: #40507d;
            -webkit-line-clamp: 1;
          }

          p {
            margin-top: .5rem;
            -webkit-line-clamp: 2;
          }
        }
      }
    }

    &-wrapper {
      height: 12em;
      margin-top: .6rem;
      overflow: hidden;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        line-height: 1.2rem;
        margin: 0;
      }

      img {
        display: none;
      }
    }
  }
}

.hover-sub-menu {
  display: flex;
  flex-wrap: wrap;
  gap: 40px 24px;

  &-col {
    display: flex;
    flex-direction: column;

    // margin-right: 24px;
    // &:last-child{
    //   margin-right: 0px;
    // }
    &-title {
      width: 160px;
      font-size: 14px;
      color: $main-color;
      font-weight: 500;
      line-height: 20px;
      padding-bottom: 8px;
      border-bottom: 1px solid #E7E9F0;
      // &:hover {
      //   color: $sub-color
      // }
    }

    &-child {
      font-weight: 300;
      font-size: 14px;
      color: $main-color;
      line-height: 20px;
      margin-top: 8px;
      cursor: pointer;

      &:hover {
        color: $sub-color
      }
    }
  }
}