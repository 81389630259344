.popover {
  .popover-content {
    padding: 16px 40px;
    background-color: #fff;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.08);
    border-radius: 8px;
    border: none;
    outline: none;

  }
}
