@import 'styles/media';
@import 'styles/global';

.banner-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 160px !important;
  display: flex;
  align-items: center;
  justify-content: space-around;
  // box-shadow: inset 0 2px 4px 0 rgba(255,255,255,0.40);
  padding: 0px 100px;
  z-index: 10;
  .filter-blur {
    background-color: rgba($color: #000000, $alpha: 0.5);
    backdrop-filter: blur(20px);
  }

  @include media("<tablet") {
    position: static;
    overflow-y: auto;
    padding: 12px 20px;
    justify-content: flex-start;
    gap: 0px 8px;
    // margin-bottom: 28px;
    align-items: flex-start;
    height: auto !important;
  }

  .item {
    padding: 24px;
    color: #fff;
    @include media("<tablet") {
      width: 220px;
      min-height: 132px;
      flex:  0 0 auto;
      background-color: #FAFAFA;
      border-radius: 8px;
      padding: 20px 16px;
      color: $main-color;
    }
    .title {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      // color: #01000E;
      @include media("<tablet") {
        display: block;
        font-size: 14px;
        line-height: 20px;
      }
      &>img {
        width: 20px;
        height: 20px;
      }
      .text {
        margin-left: 4px;

        @include media("<tablet") {
          margin-left: 0px;
          margin-top: 8px;
        }
      }
    }
    .content {
      display: -webkit-box;
      font-weight: 300;
      font-size: 14px;
      height: 48px;
      margin-top: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      // color: #01000E;
      @include media("<tablet") {
        margin-top: 4px;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

}
