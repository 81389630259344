@import 'styles/global.scss';

.comp-dropdown-list {
  min-width: 80px;
  font-size: .8rem;
  color: $main-color;
  padding-left: 12px;
  height: 22px;
  cursor: pointer;
  .comp-dropdown-overlay {
    padding: 6px 0px;
  }
  .dropdown-label-line {
    opacity: 0.3;
  }
  .dropdown-label {
    display: flex;
    align-items: center;
  }
  &.dark {
    >div {
      color: #fff;
    }
  }

  >div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // height: 1.5rem;
    line-height: 22px;
    transition: color .3s;

    .icon {
      // margin-left: .6rem;
      fill: $main-color;
    }
  }

  ul {
    user-select: none;
    outline: none;
    &.show {
      display: block;
    }
    li {
      display: flex;
      height: 1.4rem;
      line-height: 1.4rem;
      align-items: center;
      padding: 0 1rem;
      cursor: pointer;

      &.active {
        &::after {
          content: '✓';
          flex: 1;
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
          font-weight: normal;
          color: #4685e8;
          text-align: right;
          margin-top: -1PX;
        }
      }

      &[value=cn] {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-weight: normal;
      }

      &:hover {
        background-color: #f3f9fc;
      }

      img {
        width: 1rem;
        height: 1rem;
        margin-right: .3rem;
      }
    }
  }
}
