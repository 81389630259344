@font-face {
  font-family: 'Avenir-Medium';
  src: url('/static/media/Avenir-Medium-woff-7.ttf');
}

@font-face {
  font-family: 'Avenir-Roman';
  src: url('/static/media/Avenir-Roman-8.ttf');
}

html {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 20px;
  -webkit-tap-highlight-color: transparent;
}

html * {
  box-sizing: border-box;
}
body {
  position: relative;
}

// language styles
.en {
  font-family: 'Avenir-Roman', Helvetica, sans-serif;
  font-weight: 300;
}

ul, li {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

a {
  outline: none;
  text-decoration: none;
}

.clearfix::after {
  content: '';
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}

@media screen and (max-width: 768px) {
  html {
    font-size: 14px;
  }
}

@media screen and (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

@media screen and (min-width: 1200px) {
  html {
    font-size: 20px;
  }
}

