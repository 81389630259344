@import 'styles/media';
@import 'styles/global';

$pc-height: 320px;
$mobile-height: 200px;

.comp-banner {
  position: relative;
  flex-direction: column;
  width: 100%;
  min-height: $mobile-height;
  overflow: hidden;

  @include media(">=tablet") {
    min-height: $pc-height;
  }

  &.large {
    .banner-title {
      @include media(">desktop") {
        font-size: 2.2rem;
      }
    }

    .banner-legend {
      font-size: 1.2rem;
      line-height: 1.8rem;
    }
  }

  &.covered {
    .banner-wrapper {
      padding-top: 64PX;

      @include media(">=tablet") {
        padding-top: 150PX;
      }
    }
  }

  .banner-wrapper {
    min-height: $mobile-height;
    padding-top: 32PX;
    padding-bottom: 32PX;
    background-size: cover;
    background-position: center;
    position: relative;

    @include media(">=tablet") {
      min-height: $pc-height;
      padding-top: 100PX;
      padding-bottom: 100PX;
    }
  }

  .banner-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;

    @include media("<tablet") {
      position: absolute;
      bottom: 128px;
      text-align: center;
      padding: 0px;
      width: 90%;
      margin-left: 5%;
    }

    &.left {
      width: 80%;
    }

    &.center {
      text-align: center;
    }

    // 如果是暗色背景，则为其适配亮色文字
    &.dark {

      .banner-title,
      .banner-sub-title,
      .banner-legend {
        color: #fff;
      }
    }

    >div {
      margin: .5rem 0;
    }
  }

  .banner-title-wrapper {
    display: flex;
    align-items: center;

    @include media("<tablet") {
      justify-content: center;
    }
  }

  .banner-title,
  .banner-sub-title {
    font-size: 1.5rem;
    color: #01000E;

    @include media("<tablet") {
      font-size: 24px;
    }

    @include media(">=tablet") {
      font-size: 1.8rem;
    }

    @include media(">desktop") {
      font-size: 2rem;
    }
  }

  .banner-sub-title {
    font-weight: 300;
    margin-left: 12px;

    @include media("<tablet") {
      font-weight: 200;
      margin-left: 4px;
    }
  }

  .banner-legend {
    font-size: 1rem;
    line-height: 1.6rem;
    color: #01000E;
    opacity: .9;
    font-weight: 300;

    @include media("<tablet") {
      font-size: 12px !important;
      line-height: 16px !important;
    }

    @include media(">=tablet") {
      font-size: .9rem;
    }

    p {
      margin: 0 0 .3rem;
    }
  }

  .banner-operation {
    @include media("<tablet") {
      padding: 16px 0px;
      display: flex;
      justify-content: center;
      background-color: #F5F5F7;
    }

    @include media(">tablet") {
      >a {
        margin-right: 20px;
      }
    }
  }

  >div,
  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide>div {
    height: 100%;
  }

  .slick-dots {
    bottom: 196px;
    text-align: left;
    transition: all .3s;
    display: flex;
    justify-content: center;

    @include media("<tablet") {
      top: 290px;
    }

    >ul {
      transition: all .3s;

      &.ing {
        opacity: .1;
      }
    }

    li {
      width: auto;
      height: auto;
      margin: 0 .8rem 0 0;

      &.slick-active {
        button {
          &::after {
            width: 40px;
            visibility: visible;
          }
        }
      }

      button {
        position: relative;
        width: 40px;
        height: 4px;
        padding: 0;
        border-radius: 1px;
        background-color: #fff;

        @include media("<tablet") {
          border-radius: 1px;
          background-color: rgba($color: #000000, $alpha: 0.2);
        }

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 0;
          height: 100%;
          border-radius: 1px;
          background-color: $sub-color;
          visibility: hidden;
          transition: width 5s linear;
        }

        &::before {
          display: none;
        }
      }
    }
  }
}

/****** en ******/
.en {
  .comp-banner {
    &.large {
      .banner-title {
        @include media(">desktop") {
          font-size: 1.7rem;
        }
      }
    }

    .banner-title {
      font-size: 1.4rem;
      ;

      @include media(">=tablet") {
        font-size: 1.5rem;
      }

      @include media(">desktop") {
        font-size: 1.6rem;
      }
    }

    .banner-content {
      >div {
        margin: .3rem 0;
      }
    }
  }
}