@import 'styles/media';
@import 'styles/global';

.footer {
  &-root {
    height: 460px;
    border-top: 1px solid rgba(202, 202, 216, .4);
    position: relative;
    @include media("<tablet") {
      border-top: none;
    }
    .footer-img {
      z-index: -1;
      position: absolute;
      top: 90px;
      left: 28%;
      width: 500px;
      @include media("<tablet") {
        // display: none;
        top: 40px;
        left: 20px;
        width: 90%;
        // opacity: 0.5;
      }
    }
    // margin-top: 4rem;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-image: url('/static/images/footer-bg.png');
    @include media("<=tablet") {
      background-image: none;
    }
  }

  &-top {
    padding-bottom: 1.25rem;
    padding-top: 6rem;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include media("<=tablet") {
      flex-direction: column;
      padding: 40px 20px 0px 20px;
    }
  }

  &-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 34px;
    padding: 4px 48px;
    text-align: center;
    // margin-bottom: 1rem;
    border-top: 1px solid #e7e9f0;
    @include media("<tablet") {
      border-top: none;
      flex-direction: column-reverse;
      margin-top: 50px;
      height: auto;
    }

    &-logo {
      width: 60px !important;
      height: auto !important;
    }
    &-detail {
      display: flex;
      align-items: center;
      min-height: 24px;
      @include media("<tablet") {
        flex-direction: column;
        gap: 0px 4px;
             @include media("<tablet") {
        margin-top: 16px;
        margin-bottom: 20px;
      }
      }
    }
    &-txt-prefix {
      @include media("<tablet") {
        // display: none;
      }
    }

    &-txt,
    & a {
      color: rgba($color: #01000E, $alpha: 0.3) !important;
      line-height: 1rem;

      @include media(">=tablet") {
        font-size: .6rem;
      }
    }

    img {
      width: 14px;
      height: 14px;
      margin-right: .2rem;
    }
  }

  &-left {
    display: flex;
    flex-direction: column;

    @include media("<=tablet") {
      align-items: center;
      margin-top: 40px;
    }
    .contact-us {
      font-weight: 500;
      font-size: 32px;
      color: #01000E;
      margin-bottom: 24px;
      @include media("<tablet") {
        font-size: 20px;
        margin-bottom: 48px;
      }
    }
    .contact-line {
      display: flex;
      align-items: center;
      margin-bottom: .8rem;
      @include media("<tablet") {
        margin-bottom: 4px;
      }
    }

    h2 {
      margin: 0;
      padding: 0;
      margin-bottom: .5rem;
    }

    &-icon {
      position: relative;
      width: 6.5rem;
      margin: 0;
      padding: 0;
      text-align: left;
      z-index: 1;
    }

    &-text {
      margin-left: 8px;
      color: #919BAF;
      @include media(">=tablet") {
        font-size: .8rem;
      }
      @include media("<tablet") {
        color: #01000E;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
      }

    }
  }

  &-right {
    display: flex;
    flex-direction: row;

    @include media("<=tablet") {
      flex-direction: column;
    }
  }
}

.footer-item {
  &-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    @include media("<tablet") {
      flex-direction: column;
      padding: 0px;

      a {
        padding: 14px 12px;
        font-weight: 400;
        line-height: 20px;
        color: $main-color;
        margin-bottom: 0px;
        background-color: #F5F5F7;
        &:not(:last-child)::after {
          content: '';
          display: block;
          padding: 0px 12px;
          border-bottom: 1px solid #E7E9F0;
          position: relative;
          top: 14px;
        }
        &:first-of-type {
          border-radius: 8px 8px 0px 0px;
        }
        &:last-child {
          border-radius: 0px 0px 8px 8px;
        }
      }
    }

    @include media(">=tablet") {
      margin-right: 24px;
      width: 112px;
      flex-direction: column;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-title {
    width: 100%;
    font-size: 1.15rem;
    color: $main-color;
    line-height: .9rem;
    padding-bottom: 0.6rem;
    margin-bottom: 0.4rem;
    border-bottom: 1px solid #E7E9F0;

    @include media("<tablet") {
      margin-top: 32px;
      font-weight: 500;
      font-size: 12px;
      color: #9196AA;
      line-height: 16px;
      border-bottom: none;
    }

    @include media(">=tablet") {
      font-size: .9rem;
    }
  }

  &-txt {
    color: $main-color !important;
    font-weight: 300;
    font-size: 14px !important;
    margin-bottom: .8rem;

    @include media("<tablet") {
      font-weight: 400;
      font-size: 12px !important;
    }

    @include media(">=tablet") {
      font-size: .8rem;
      &:hover {
        color: #3C9FDB !important;
      }
    }

  }

  &-img {
    display: flex;
    flex-direction: column;

    img {
      display: none;
      width: 5rem;
      height: 5rem;
      margin-bottom: .8rem;
    }
    @include media("<tablet") {
      flex-direction: column-reverse;
      align-items: center;
    }
    .content-img {
      @include media("<tablet") {
        width: 96px;
        height: 96px;
      }
    }
  }
}

.modal-qrcode-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 3rem;

  img {
    width: 15rem;
    height: 15rem;
  }
}
