.tab-carousel-wrap {
  position: relative;
  overflow: hidden;
  .tab-carousel-tabs{
    height: 190px;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 0px;
    left: 0px;
    z-index: 1;
    .tabs-title {
      margin-top: 72px;
    }
    .tabs-items {
      margin-top: 32px;
      display: flex;
      .tabs-item {
        cursor: pointer;
        margin: 0px 20px;
        padding-bottom: 14px;
        // height: 24px;
        opacity: 0.8;
        font-weight: 300;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 24px;
        &-active {
          font-weight: 600;
          font-size: 16px;
          opacity: 1;
          border-bottom: 2px solid #fff;
        }
      }
    }
  }
  .content {
    height: 100%;
    width: 100%;
    .content-item {
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 1;
      .content-inner {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 262px;
        color: #fff;
        padding: 12px 0px;
        backdrop-filter: blur(8px);
        &>div {
          width: 672px;
          margin: 0 auto;
        }
        .title {
          font-weight: 500;
          font-size: 32px;
          line-height: 45px;
        }
        .desc {
          margin-top: 12px;
          font-weight: 300;
          font-size: 16px;
          line-height: 22px;
          height: 66px;
          overflow: hidden;
        }
        .tips {
          margin-top: 12px;
          font-weight: 500;
          font-size: 12px;
        }
        .case-wrapper {
          margin: 0 auto;
          margin-top: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .icon-wrapper {
            display: flex;
            flex-wrap: wrap;
            max-width: 600px;
            .icon {
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 12px;
              width: 84px;
              height: 32px;
              background-color: #fff;
              border-radius: 8px;
              margin-top: 10px;
              img {
                max-width: 65px;
                max-height: 25px;
              }
            }
          }
        }
      }
    }
  }
}
