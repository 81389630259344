@import 'styles/global';
@import 'styles/media';

.buttonBase {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  padding: 13px 54px;
  border-radius: 100px;
  text-decoration: none;
  user-select: none;
  text-align: center;
  cursor: pointer;

  @include media('<tablet') {
    font-weight: 400;
    font-size: 14px;
    padding: 10px 43px;
  }

  &.disabled {
    color: #fff;
    background-color: #e2e2e2 !important;
    pointer-events: none;
  }

  .icon {
    position: absolute;
    top: calc(50% - 8px);
    right: 20px;
  }
}

.buttonPrimary {
  @extend .buttonBase;
  border: none;
  color: #fff;

  .icon {
    color: rgba($color: #fff, $alpha: 0.3);
  }
}

.buttonDefault {
  @extend .buttonBase;
}

.buttonWhite {
  @extend .buttonBase;
  border: none;
  background-color: #fff;
  color: $main-color;
}

.button-video-icon {
  display: flex;
  cursor: pointer;

  .button-video-circle {
    background-color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 40px;
  }

  .button-video-text {
    position: relative;
    left: -40px;
    padding-left: 58px;
    padding-right: 28px;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    height: 40px;
    line-height: 40px;
    background-color: $sub-color;
    z-index: -1;
    border-radius: 25px;
  }
}