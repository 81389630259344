@import 'styles/media';

.content-detail {
  width: 720px;
  margin: 0 auto;
  padding-top: 3rem;
  padding-bottom: 4rem;
  @include media("<tablet") {
    width: auto;
    padding-top: 24px;
  }


  &-footer {
    color: #7e8697;
    font-size: 1.1rem;
    text-align: right;
    line-height: 1.5rem;

    @include media(">=tablet") {
      font-size: .8rem;
    }
  }
}
