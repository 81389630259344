@import 'styles/global';
@import 'styles/media';

.news {
  min-height: 300px;
  padding: 72px 0px;
  background-color: #F5F5F7;
  height: 760px;
  @include media("<tablet") {
    padding: 24px 0;
    height: 414px;
  }
  .news-container {
    max-width: $normal-width;
    margin: 0 auto;
    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      .main {
        line-height: 45px;
        font-weight: 500;
        font-size: 32px;
        color: #01000E;
      }
      .sub {
        margin-top: 8px;
        line-height: 25px;
        font-weight: 300;
        font-size: 18px;
        color: $gray-color;
      }
    }
    .content-wrap {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0 12px;
      overflow: auto;
    }
    .content {
      // max-width: 100%;
      margin: 0 auto;
      margin-top: 48px;
      display: flex;
      justify-content: left;
      @include media("<tablet") {
        margin-top: 16px;
        gap: 0px 12px;
      }
      .card {
        width: 390px;
        padding: 24px;
        cursor: pointer;
        border-radius: 8px;
        @include media("<tablet") {
          width: 280px;
          padding: 0px;
          background-color: #fff;
        }
        &:hover {
          background-color: rgba($color: #fff, $alpha: 0.4);
          box-shadow: 0 2px 16px 0 rgba(0,0,0,0.08);
          @include media("<tablet") {
            background-color: inherit;
            box-shadow: none;
          }
        }

        .pic {
          height: 188px;
          width: 342px;
          background-color: $gray-color;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 50%;
          @include media("<tablet") {
            height: 154px;
            width: 280px;
            border: 1px solid rgba(255,255,255,0.20);
            border-radius: 8px 8px 0 0;
          }
        }
        .title-box {
          height: 110px;
          @include media("<tablet") {
            height: auto;
            padding: 0px 12px;
          }
          .title {
            margin-top: 16px;
            font-weight: 600;
            font-size: 18px;
            color: #01000E;
            line-height: 24px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;
            @include media("<tablet") {
              display: block;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              font-size: 14px;
              line-height: 20px;
              margin-top: 12px;
            }
          }
          .sub-title {
            margin-top: 12px;
            font-weight: 300;
            font-size: 16px;
            color: $gray-color;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            @include media("<tablet") {
              font-size: 12px;
              line-height: 18px;
              margin-top: 2px;
            }
          }
        }

        .time {
          font-weight: 300;
          font-size: 16px;
          line-height: 24px;
          color: #01000E;
          margin-bottom: 8px;
          @include media("<tablet") {
            margin-top: 16px;
            margin-bottom: 12px;
            font-size: 12px;
            line-height: 16px;
            padding: 0px 12px;
          }
        }
      }
    }
    .button {
      display: flex;
      justify-content: center;
      margin-top: 48px;
      @include media("<tablet") {
        margin-top: 16px;
      }
    }
  }

}
