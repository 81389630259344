.form-creator-radio-group {
  font-size: .9rem;
  color: #7e8697;
  margin-top: -0.5rem;

  &-row {
    margin-top: .5rem;
  }

  input[type="radio"] {
    position: absolute;
    opacity: 0;
    padding: .5rem;

    & + span:before {
      content: '';
      border-radius: 50%;
      border: 1px solid #cbcbd9;
      display: inline-block;
      width: 1rem;
      height: 1rem;
      margin-right: .5rem;
      vertical-align: middle;
      cursor: pointer;
      transition: all 250ms ease;
    }

    &:checked + span:before {
      background-color: #3c9fdb;
      box-shadow: inset 0 0 0 4px #fff;
    }

    &:focus + span:before {
      outline: none;
      border-color: #3c9fdb;
    }

    &:disabled + span:before {
      box-shadow: inset 0 0 0 4px #fff;
      border-color: #cbcbd9;
      background: #cbcbd9;
    }

    & + span:empty:before {
      margin-right: 0;
    }
  }
}
