@import 'styles/media';

.case-detail {
  .comp-banner .banner-content {
    align-items: center;
  }

  .banner-title {
    font-size: 32px !important;
  }
}

.comp-page-center {

  .about-content {
    padding-top: 48px;

    @include media("<tablet") {
      padding-top: 40px;
    }

    .case-detail-tip {
      font-weight: 300;
      font-size: 16px;
      color: #9196AA;
      text-align: center;
      line-height: 24px;
    }
  }
}