@import 'styles/global';
@import 'styles/media';

.news-wrapper {
  padding-top: $header-height;

  @include media('<tablet') {
    padding: 0px 20px;
    padding-top: 16px;
  }

  .news-content {
    width: 1044px;
    margin: 0 auto;

    @include media('<tablet') {
      width: auto;
    }

    .news-title {
      margin-top: 48px;
      margin-bottom: 32px;
    }
  }
}

.news-carousel-mb {
  margin-bottom: 32px;

  @include media('<tablet') {
    margin-bottom: 40px;
  }
}

.news-carousel {
  position: relative;
  width: 1044px;

  @include media('<tablet') {
    width: auto;
  }

  .circle-left {
    position: absolute;
    left: 0;
    top: calc(50% - 24px);
  }

  .circle-right {
    position: absolute;
    right: 0;
    top: calc(50% - 24px);
  }

  &-slider {
    margin-left: 72px;

    @include media('<tablet') {
      margin-left: 0px;
    }

    .news-carousel-content {
      cursor: pointer;
      position: relative;
      width: 900px;
      height: 416px;
      border-radius: 6px;

      @include media('<tablet') {
        width: calc(100vw - 40px);
        height: calc(50vw - 20px);
        border-radius: 8px 8px 0 0;
      }
    }
  }

  .news-carousel-desc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 40px;
    position: absolute;
    bottom: 0;
    left: 72px;
    width: 900px;
    height: 100px;
    backdrop-filter: blur(12px);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    @include media('<tablet') {
      position: static;
      width: calc(100vw - 40px);
      background-color: #FAFAFA;
      box-shadow: 0 2px 0 0 #0366DD;
      border-radius: 0 0 8px 8px;
      height: auto;
      padding: 12px 20px;
      margin-bottom: 12px;

      .main-title-lg {
        color: $main-color;
      }

      .main-sub-title {
        color: #9196AA !important;
        font-weight: 300;
        font-size: 14px;
        margin-top: 4px;
      }

      .cursor-circle-wrapper {
        position: absolute;
        bottom: -22px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .news-carousel-desc-mb {
    // @include media('<tablet') {
    //   margin-bottom: 24px;
    // }
  }
}

.news-list-wrapper {
  width: 900px;
  margin: 0 auto;
  padding-bottom: 64px;

  @include media('<tablet') {
    display: flex;
    flex-direction: column;
    width: auto;
  }

  .news-list-row {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 32px 0px;

    @include media('<tablet') {
      flex-direction: column;
      padding: 0px;
    }

    &-item {
      cursor: pointer;
      width: 280px;

      @include media('<tablet') {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        // justify-content: space-between;
        padding: 16px 0px;
      }

      &:hover .news-list-row-item-title {
        color: $sub-color;
      }

      &-img {
        width: 280px;
        height: 158px;
        display: inline-block;
        border-radius: 8px;
        object-fit: cover;

        @include media('<tablet') {
          width: 128px;
          height: 72px;
          flex: 0 0 auto;
          margin-left: auto;
        }
      }

      &-text {
        height: 100px;
        overflow: hidden;

        @include media('<tablet') {
          flex: 1 1 auto;
          height: 48px;
          width: 100%;
          padding-right: 10px;
          // width: 0px;
        }
      }

      &-title {
        margin-top: 16px;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: #2A2B2E;

        @include media('<tablet') {
          margin-top: 0px;
          font-size: 16px;
          line-height: 24px;
        }

      }

      &-content {
        margin-top: 4px;
        font-weight: 300;
        font-size: 16px;
        line-height: 22px;
        color: $gray-color;
      }

      &-time {
        margin-top: 24px;
        font-weight: 300;
        font-size: 16px;
        color: #676A76;
        line-height: 16px;

        @include media('<tablet') {
          margin-top: 8px;
          font-size: 12px;
        }
      }
    }

    .row-line {
      position: absolute;
      bottom: 0;
    }
  }

  .news-pagination {
    justify-content: flex-end;
    margin-top: 32px;
  }

}