@import 'styles/global';
.mobile-tabs-wrapper {
  // display: flex;
  // align-items: center;
  position: relative;
  width: 100%;
  height: 52px;
  background-color: #F5F5F7;
  overflow: auto;
  white-space: nowrap;
  z-index: 2;
  &>a {
    display: inline-block;
    padding: 16px 20px;
    color: #9196AA;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  .active {
    color: $sub-color;
  }
}
.anchor-wrapper {
  div {
    padding-left: 20px;
    margin-bottom: 12px;
    color: $gray-color;
  }
  .active {
    height: 24px;
    background-image: linear-gradient(90deg, #DEEDFF 0%, rgba(255,255,255,0) 100%);
    border-radius: 4px;
    color: $sub-color;
    position: relative;
    &::before {
      position: absolute;
      left: 10px;
      top: 1px;
      content: '·';
      font-weight: 900;
      font-size: 20px;
      line-height: 20px;
    }
  }
  a {
    height: 24px;
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: inherit;
  }
}
