 $prefix: i;

 .#{$prefix}-icon {
     display: inline-block;
     color: inherit;
     font-style: normal;
     line-height: 0;
     text-align: center;
     text-transform: none;
     vertical-align: -0.125em;
     text-rendering: optimizeLegibility;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;

     &-spin svg {
         animation: i-icon-spin 1s infinite linear;
     }

     &-rtl {
         transform: scaleX(-1);
     }
 }

//  @keyframes #{$prefix}-icon-spin {
//      100% {
//          -webkit-transform: rotate(360deg);
//          transform: rotate(360deg)
//      }
//  }

//  @-webkit-keyframes #{$prefix}-icon-spin {
//      100% {
//          -webkit-transform: rotate(360deg);
//          transform: rotate(360deg)
//      }
//  }

