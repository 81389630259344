@import 'styles/global';
@import 'styles/media';

.go-banner {
  width: 100%;
  height: 700px;
  position: relative;

  .go-banner-background {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  .go-banner-inner {
    width: 1200px;
    height: 100%;
    padding-top: 110px;
    position: relative;
    margin: 0 auto;

    .go-banner-inner-img1 {
      width: 100%;
      position: absolute;
      bottom: 50px;
      left: 0;
      z-index: 0;
    }

    .go-banner-inner-img2 {
      width: 100%;
      z-index: 2;
      position: relative;
    }

    .go-banner-left {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 165px;
      left: 210px;
      z-index: 3;

      img {
        width: 400px;
        height: 168px;
      }

      .go-banner-left-img1 {}

      .go-banner-left-img2 {
        margin-top: 16px;
      }
    }
  }
}

.go-product {
  position: relative;
  width: 100%;
  height: 700px;

  .go-product-background {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  .go-product-inner {
    width: 1200px;
    height: 100%;
    position: relative;
    margin: 0 auto;
    padding-top: 40px;

    .go-product-title,
    .go-product-content {
      width: 100%;
    }
  }
}