@import 'styles/global';
.common-pagination {
  display: flex;
  gap: 0px 8px;
  .common-pagination-disabled {
    opacity: 0.5;
    &:hover {
      cursor: not-allowed !important;
      background-color: transparent !important
    }
  }
  .pagination-base {
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 4px;
    border: 1px solid transparent;
    &:hover {
      cursor: pointer;
      background-color: rgba($color: $main-color, $alpha: 0.1)
    }
  }
  .common-pagination-prev, .common-pagination-next,.common-pagination-jump-next,.common-pagination-jump-prev {
    @extend .pagination-base;
  }
  .common-pagination-jump-next,.common-pagination-jump-prev {
    &:hover {
      @extend .common-pagination-item-active;
    }
  }
  .common-pagination-item  {
    font-weight: 400;
    font-size: 14px;
    color: $main-color;
    @extend .pagination-base;
  }
  .common-pagination-item-active {
    border: 1px solid $sub-color;
    color: $sub-color;
    background-color: #daebff;
    &:hover {
      cursor: pointer;
      background-color: #daebff
    }
  }
}
