@import 'styles/media';

.brands-wall-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 276px;
  @include media('<tablet') {
    height: 220px;
  }
}

.brands-wall {
  position: relative;
  width: 100%;
  @include media('<tablet') {
    &::after,&::before{
      display: none;
    }
  }
  .mobile-wall-page {
    display: flex;
    justify-content: center;
    padding: 16px;
    margin-bottom: 40px;
    @include media('>=tablet') {
      display: none;
    }
  }
  &::after,
  &::before {
    content: '';
    position: absolute;
    top: 0;
    width: 10%;
    height: 100%;
    z-index: 1;
    pointer-events: none;
  }

  &::after {
    right: 0;
    background-image: linear-gradient(to left, rgba(255, 255, 255, .9) , rgba(255, 255, 255, .0));
  }

  &::before {
    left: 0;
    background-image: linear-gradient(to right, rgba(255, 255, 255, .9) , rgba(255, 255, 255, .0));
  }
}

.brands-item-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 72px;
    width: 180px;
    margin-right: 12px;
    margin-bottom: 12px;
    padding: .8rem 1.6rem;
    transition: all .3s;
    border: 1px solid rgba(1,0,14,0.08);
    border-radius: 8px;
    @include media('<tablet') {
      height: 44px;
      width: 109px;
      background-color: #FFFFFF;
      margin-right: 4px;
      margin-bottom: 4px;
      border-radius: 4px;
    }
    &:hover {
      background-color: #FFFFFF;
      box-shadow: 0 2px 12px 0 rgba(0,0,0,0.08);
    }

    .icon,
    img {
      display: inline-block;
      max-width: 100%;
      max-height: 100%;
      vertical-align: middle;
    }
  }
}
