@import 'styles/media';

.live-button {
  position: absolute;
  top: 16px;
  left: 16px;
  height: 24px;
  line-height: 24px;
  background-color: #0366DD;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  z-index: 3;
  padding: 0 8px;

  img {
    width: 14px !important;
    height: 14px !important;
    vertical-align: middle;
    margin-right: 6px;
  }
}

.live-jump {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 2;
  display: flex;
  align-items: end;
  justify-content: end;

  .buttonPrimary {
    background-color: #f0a055 !important;
    border: 1px solid #fff;
    margin-right: 30px;
    margin-bottom: 30px;
  }
}