@import 'styles/global';
@import 'styles/media';

.cases-list-wrapper {
  position: relative;
  padding-top: 62px;
  padding-bottom: 72px;
  width: 1220px;
  margin: 0 auto;

  @include media('<tablet') {
    width: auto;
    padding-top: 0px;
  }

  .affix-list {
    position: absolute;
    width: 280px;
  }

  .cases-list {
    width: 1220px;
    padding-left: 320px;

    @include media('<tablet') {
      padding: 0px 20px;
      margin-top: 40px;
      width: auto;
    }

    &-group {
      margin-bottom: 64px;

      @include media('<tablet') {
        margin-bottom: 40px;
      }

      &-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 40px;
        color: $main-color;

        @include media('<tablet') {
          text-align: center;
        }
      }

      &-content {
        &-row {
          display: flex;
          gap: 0px 30px;
          margin: 32px 0px;

          @include media('<tablet') {
            margin: 16px 0px;
            gap: 0px 20px;
          }
        }

        &-item {
          cursor: pointer;
          height: 320px;
          $imgWidth: calc(50vw - 30px);
          $imgHeight: calc($imgWidth * 0.557);

          @include media('<tablet') {
            height: 210px;
          }

          .item-img-wrapper {
            position: relative;
            font-size: 0px;
            height: 158px;

            @include media('<tablet') {
              z-index: -1;
              height: 88px;
            }

            .item-img {
              height: 158px;
              width: 280px;
              object-fit: cover;
              border-radius: 8px;

              @include media('<tablet') {
                width: $imgWidth;
                height: $imgHeight;
              }
            }

            .item-play {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translateX(-50%) translateY(-50%);
              // @include media('<tablet') {
              //   width: $imgWidth;
              //   height: $imgHeight;
              //   left: 0;
              //   top: 0;
              //   transform: none;
              //   display: flex;
              //   justify-content: center;
              //   align-items: center;
              //   .video-circle {
              //     transform: translateX(-50%);
              //   }
              // }
            }
          }

          .item-logo {
            margin-top: 18px;
            height: 24px;

            @include media('<tablet') {
              margin-top: 16px;
            }
          }

          .item-title {
            margin-top: 8px;
            width: 280px;
            font-weight: 500;
            font-size: 14px;
            color: #01000E;
            line-height: 20px;
            height: 40px;

            @include media('<tablet') {
              width: $imgWidth;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
          }

          @include media('>=tablet') {
            &:hover>.item-detail {
              opacity: 1;
            }
          }

          .item-detail {
            opacity: 0;
            margin-top: 24px;
            color: $sub-color;
            font-weight: 500;
            font-size: 12px;
            color: $sub-color;
            line-height: 16px;
            transition: opacity .3s;

            @include media('<tablet') {
              opacity: 1;
              margin-top: 16px;
            }
          }
        }
      }
    }
  }
}